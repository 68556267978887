define("teelt-frontend/components/analytics/add-form-analytic", ["exports", "teelt-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['form-analytic-detail'],
    store: Ember.inject.service(),
    intl: Ember.inject.service(),
    session: Ember.inject.service(),
    userService: Ember.inject.service('user-service'),
    ajax: Ember.inject.service(),
    eventBus: Ember.inject.service('event-bus'),
    router: Ember.inject.service(),
    formPath: _environment.default.APP.API_HOST + "/forms",
    ihmPath: _environment.default.APP.API_HOST + "/meetpeoples",
    error: {},
    formReport: null,
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      if (this.formReportId) {
        Ember.set(this, 'showFormSelect', false);
        this.store.findRecord('form-report', this.formReportId, {
          reload: true,
          include: 'light'
        }).then(function (formReport) {
          Ember.set(_this, 'formReport', formReport);
        });
      } else {
        var formReport = this.store.createRecord('form-report', {
          form: {},
          template: {},
          type: "form",
          meetPeople: {},
          title: null,
          description: null,
          formFields: null,
          responseNbByMonth: null
        });
        Ember.set(this, 'formReport', formReport);
      }
    },
    didInsertElement: function didInsertElement() {
      var _this2 = this;

      this._super.apply(this, arguments);

      setTimeout(function () {
        _this2.send('onTypeChange', _this2.formReport.type);

        _this2.$('.select').formSelect();
      }, 300);
    },
    validateFormReport: function validateFormReport() {
      Ember.set(this.error, 'titleIsNull', undefined);
      Ember.set(this.error, 'formIsNull', undefined);

      if (!this.formReport.title || this.formReport.title.trim() == '') {
        Ember.set(this.error, 'titleIsNull', true);
        return false;
      }

      if (this.formReport.type == "form" && (!this.formReport.form || this.formReport.form.id == null)) {
        Ember.set(this.error, 'formIsNull', true);
        return false;
      }

      return true;
    },
    actions: {
      save: function save() {
        var _this3 = this;

        if (!this.validateFormReport()) return;

        if (this.formReport.type != "form") {
          Ember.set(this.formReport, 'form', null);
        }

        if (this.formReport.type != "ihm") {
          Ember.set(this.formReport, 'meetPeople', null);
        }

        this.formReport.save().then(function (formReport) {
          _this3.send('previous');
        }).catch(function (err) {
          console.log("err", err);
        });
      },
      previous: function previous() {
        this.router.transitionTo('board.analytics');
      },
      onFormChange: function onFormChange(data) {
        var form = {
          id: data
        };
        Ember.set(this.formReport, 'form', form);
      },
      onIhmChange: function onIhmChange(data) {
        var meetPeople = {
          id: data
        };
        Ember.set(this.formReport, 'meetPeople', meetPeople);
      },
      onTemplateChange: function onTemplateChange(data) {
        data = data == "all" ? null : data;
        var template = {
          id: data
        };
        Ember.set(this.formReport, 'template', template);
      },
      onTypeChange: function onTypeChange(data) {
        var _this4 = this;

        Ember.set(this.formReport, 'type', data);
        Ember.set(this, 'showTemplateSelect', false);
        Ember.set(this, 'showFormSelect', false);
        Ember.set(this, 'showIhmSelect', false);

        switch (data) {
          case "form":
            Ember.set(this, 'defaultValue', this.formReport.form && this.formReport.form.title ? this.formReport.form.title : null);
            Ember.set(this, 'defaultValueId', this.formReport.form && this.formReport.form.id ? this.formReport.form.id : null);
            setTimeout(function () {
              Ember.set(_this4, 'showFormSelect', true);
            }, 100);
            break;

          case "ihm":
            Ember.set(this, 'defaultValue', this.formReport.meetPeople ? this.formReport.meetPeople.title : null);
            Ember.set(this, 'defaultValueId', this.formReport.meetPeople ? this.formReport.meetPeople.id : null);
            setTimeout(function () {
              Ember.set(_this4, 'showIhmSelect', true);
            }, 100);
            break;

          case "welcome":
          case "migration":
          case "end":
            if (this.formReport.template) {
              Ember.set(this, 'defaultValue', this.formReport.template.name);
              Ember.set(this, 'defaultValueId', this.formReport.template.id);
            } else {
              Ember.set(this, 'defaultValue', this.intl.t('template.all'));
              Ember.set(this, 'defaultValueId', 'all');
            }

            setTimeout(function () {
              Ember.set(_this4, 'templatePath', _environment.default.APP.API_HOST + "/templates?type=" + data);
              Ember.set(_this4, 'showTemplateSelect', true);
            }, 100);
            break;
        }
      }
    }
  });

  _exports.default = _default;
});