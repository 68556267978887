define("teelt-frontend/routes/board/talent/talent-detail/forms", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    eventBus: Ember.inject.service(),
    afterModel: function afterModel(model, transition) {
      var _this = this;

      setTimeout(function () {
        _this.eventBus.trigger('FORCE_OPEN_FORM', model.formId);
      }, 300);
    }
  });

  _exports.default = _default;
});