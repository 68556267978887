define("teelt-frontend/components/analytics/form-analytics-list", ["exports", "teelt-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    router: Ember.inject.service(),
    eventBus: Ember.inject.service(),
    countUrl: _environment.default.APP.API_HOST + "/form-reports/count",
    classNames: ['templates-list'],
    modelName: 'form-report',
    modelFilter: {},
    keys: ['type', 'title', 'createdAt', 'responseCount', 'actions'],
    init: function init() {
      this._super.apply(this, arguments);

      Ember.set(this, 'reloadTimestamp', new Date());
      this.eventBus.on('REFRESH_FORMREPORTS', this, 'onFormReportRefresh');
    },
    destroy: function destroy() {
      this.eventBus.off('REFRESH_FORMREPORTS', this, 'onFormReportRefresh');

      this._super.apply(this, arguments);
    },
    onFormReportRefresh: function onFormReportRefresh() {
      Ember.set(this, 'reloadTimestamp', new Date());
    },
    actions: {
      onRowSelected: function onRowSelected(result) {
        this.router.transitionTo('board.analytic.form-analytic-detail', result.id);
      }
    }
  });

  _exports.default = _default;
});