define("teelt-frontend/components/dashboard/tasks-filters", ["exports", "teelt-frontend/config/environment", "teelt-frontend/utils/jquery-utils"], function (_exports, _environment, _jqueryUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['tasks-filters'],
    store: Ember.inject.service(),
    intl: Ember.inject.service(),
    session: Ember.inject.service(),
    userService: Ember.inject.service('user-service'),
    ajax: Ember.inject.service(),
    eventBus: Ember.inject.service(),
    params: [],
    init: function init() {
      this._super.apply(this, arguments);

      this.eventBus.on('CLICK_EVENT', this, 'checkToCloseModal');

      if (this.userService.isRH(this.session.data.authenticated.user)) {
        Ember.set(this.params, "currentRole", "ROLE_RH");
      } else {
        var _iteratorNormalCompletion = true;
        var _didIteratorError = false;
        var _iteratorError = undefined;

        try {
          for (var _iterator = this.session.data.authenticated.user.roles[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
            var role = _step.value;

            if (role != "ROLE_USER") {
              Ember.set(this.params, "currentRole", role);
              break;
            }
          }
        } catch (err) {
          _didIteratorError = true;
          _iteratorError = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion && _iterator.return != null) {
              _iterator.return();
            }
          } finally {
            if (_didIteratorError) {
              throw _iteratorError;
            }
          }
        }
      }

      this.loadData();
    },
    loadData: function loadData() {
      var _this = this;

      this.getAllTasks().then(function (tasks) {
        Ember.set(_this, 'allTasks', tasks);

        if (!_this.filter) {
          Ember.set(_this, 'filter', tasks[0].name);

          _this.onFilterChangedAction(tasks[0]);
        }
      }).catch(function (error) {
        console.error(error);
      });
    },
    getAllTasks: function getAllTasks() {
      var limit = 10000;
      var path = _environment.default.APP.API_HOST + '/tasks?limit=' + limit + '&dashboard=true';

      if (this.params.search) {
        path += '&search=' + this.params.search;
      }

      return this.ajax.requestWithUrlParamPromise(path, 'GET', this.params);
    },
    filterValues: function filterValues(value) {
      this.params.search = value;
      this.loadData();
    },
    checkToCloseModal: function checkToCloseModal(e) {
      if (!(0, _jqueryUtils.default)().checkIfClickInElement(e, ["possibles-list"])) {
        var el = document.querySelector('.possibles-list');

        if (el && (el.offsetParent !== null || el.offsetWidth > 0 || el.offsetHeight > 0)) {
          return false;
        }

        if (this.showPossibleFilters) {
          Ember.set(this, 'showPossibleFilters', false);
        }
      }
    },
    actions: {
      selectFilter: function selectFilter(filter) {
        Ember.set(this, 'filter', filter.name);
        Ember.set(this, 'showPossibleFilters', false);
        this.onFilterChangedAction(filter);
      },
      hidePossibleFilters: function hidePossibleFilters() {
        Ember.set(this, 'showPossibleFilters', false);
      },
      onSearchChange: function onSearchChange(event) {
        this.filterValues(event.target.value);
      },
      showPossibleFilters: function showPossibleFilters() {
        var el = document.querySelector('.possibles-list');

        if (el && (el.offsetParent !== null || el.offsetWidth > 0 || el.offsetHeight > 0)) {
          el.parentElement.firstElementChild.firstElementChild.innerHTML = "expand_more";
          el.remove();
        }

        if (!this.showPossibleFilters) {
          Ember.set(this, 'showPossibleFilters', true);
        }
      }
    }
  });

  _exports.default = _default;
});