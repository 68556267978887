define("teelt-frontend/components/ihm/meet-people/meet-people-list-talent", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _EmberComponent$exte;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = Ember.Component.extend((_EmberComponent$exte = {
    classNames: ['forms-list-talent'],
    ajax: Ember.inject.service(),
    eventBus: Ember.inject.service(),
    session: Ember.inject.service(),
    userService: Ember.inject.service()
  }, _defineProperty(_EmberComponent$exte, "session", Ember.inject.service()), _defineProperty(_EmberComponent$exte, "init", function init() {
    this._super.apply(this, arguments);
  }), _defineProperty(_EmberComponent$exte, "destroy", function destroy() {
    this._super.apply(this, arguments);
  }), _defineProperty(_EmberComponent$exte, "didInsertElement", function didInsertElement() {
    this._super.apply(this, arguments);

    if (this.meetPeopleId) {
      this.forceLauchGame(this.forceLauchGame(this.meetPeopleId));
    }
  }), _defineProperty(_EmberComponent$exte, "meetPeoples", Ember.computed('talent', function () {
    var _this = this;

    if (!this.talent.template || !this.talent.template.periods) return;
    var meetPeoples = [];
    var _iteratorNormalCompletion = true;
    var _didIteratorError = false;
    var _iteratorError = undefined;

    try {
      for (var _iterator = this.talent.template.periods[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
        var period = _step.value;
        var _iteratorNormalCompletion2 = true;
        var _didIteratorError2 = false;
        var _iteratorError2 = undefined;

        try {
          for (var _iterator2 = period.tasks[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
            var task = _step2.value;

            if (task.type == 'meetPeople') {
              (function () {
                var meetPeople = task.meetPeople;

                var userMeetPeople = _this.talent.userMeetPeoples.find(function (ump) {
                  return ump.id == meetPeople.id;
                });

                if (userMeetPeople) {
                  Ember.set(meetPeople, 'hasBeenLaunched', true);
                }

                if (_this.talent.id == _this.session.data.authenticated.user.id) {
                  Ember.set(meetPeople, 'canAddImage', true);
                  Ember.set(meetPeople, 'canLaunchGame', true);
                }

                meetPeoples.push(meetPeople);
              })();
            }
          }
        } catch (err) {
          _didIteratorError2 = true;
          _iteratorError2 = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion2 && _iterator2.return != null) {
              _iterator2.return();
            }
          } finally {
            if (_didIteratorError2) {
              throw _iteratorError2;
            }
          }
        }
      }
    } catch (err) {
      _didIteratorError = true;
      _iteratorError = err;
    } finally {
      try {
        if (!_iteratorNormalCompletion && _iterator.return != null) {
          _iterator.return();
        }
      } finally {
        if (_didIteratorError) {
          throw _iteratorError;
        }
      }
    }

    meetPeoples.sort(function (f1, f2) {
      var f1Title = f1.title.toUpperCase();
      var f2Title = f2.title.toUpperCase();

      if (f1Title < f2Title) {
        return -1;
      }

      if (f1Title < f2Title) {
        return 1;
      }

      return 0;
    });
    return meetPeoples;
  })), _defineProperty(_EmberComponent$exte, "forceLauchGame", function forceLauchGame(meetPeopleId) {
    if (this.meetPeoples && meetPeopleId) {
      var _iteratorNormalCompletion3 = true;
      var _didIteratorError3 = false;
      var _iteratorError3 = undefined;

      try {
        for (var _iterator3 = this.meetPeoples[Symbol.iterator](), _step3; !(_iteratorNormalCompletion3 = (_step3 = _iterator3.next()).done); _iteratorNormalCompletion3 = true) {
          var meetPeople = _step3.value;

          if (meetPeople.id == meetPeopleId) {
            this.send('lauchGame', meetPeople);
            break;
          }
        }
      } catch (err) {
        _didIteratorError3 = true;
        _iteratorError3 = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion3 && _iterator3.return != null) {
            _iterator3.return();
          }
        } finally {
          if (_didIteratorError3) {
            throw _iteratorError3;
          }
        }
      }
    }
  }), _defineProperty(_EmberComponent$exte, "actions", {
    openMeetPeopleAction: function openMeetPeopleAction(meetPeopleId) {
      if (this.showActions == meetPeopleId) {
        Ember.set(this, 'showActions', undefined);
      } else {
        Ember.set(this, 'showActions', meetPeopleId);
      }
    },
    showMeetPeopleParticipants: function showMeetPeopleParticipants(meetPeople) {
      var appearEffect = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      Ember.set(this, 'meetPeopleToOpen', meetPeople);
      Ember.set(this, 'showParticipantsModal', true);
      Ember.set(this, 'showActions', false);
      Ember.set(this, 'appearEffect', appearEffect);
      Ember.set(this, 'displayBlock', 'contact');
    },
    showAddImageForm: function showAddImageForm(meetPeople) {
      Ember.set(this, 'meetPeopleToOpen', meetPeople);
      Ember.set(this, 'showAddImageModal', true);
      Ember.set(this, 'showActions', false);
    },
    showGallery: function showGallery(meetPeople) {
      Ember.set(this, 'meetPeopleToOpen', meetPeople);
      Ember.set(this, 'showGalleryModal', true);
      Ember.set(this, 'showActions', false);
      Ember.set(this, 'showParticipantsModal', false);
      Ember.set(this, 'showLauchGameModal', false);
    },
    closeAddImageModal: function closeAddImageModal() {
      Ember.set(this, 'showAddImageModal', false);
    },
    onClickOverlay: function onClickOverlay() {},
    lauchGame: function lauchGame(meetPeople) {
      Ember.set(this, 'meetPeople', meetPeople);
      if (meetPeople.hasBeenLaunched) return;
      Ember.set(this, 'showLauchGameModal', true);
    },
    onGameLauched: function onGameLauched(meetPeople) {
      var meetPeoples = this.meetPeoples;
      Ember.set(this, 'meetPeoples', null);
      var index = meetPeoples.findIndex(function (mp) {
        return mp.id == meetPeople.id;
      });
      meetPeoples[index] = meetPeople;
      Ember.set(this, 'meetPeoples', meetPeoples);
    }
  }), _EmberComponent$exte));

  _exports.default = _default;
});