define("teelt-frontend/templates/talent/integration", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZOeg4g+V",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"id\",\"talent-integration\"],[12,\"class\",[28,[\"g-talent-route \",[27,\"route-to-class\",[[23,[\"model\",\"router\",\"currentRouteName\"]]],null]]]],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"col s12 g-slider-menu-container\"],[9],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"float-right hide-on-small-only\"],[9],[0,\"\\n          \"],[7,\"div\"],[12,\"data-tooltip\",[27,\"t\",[\"menu.profile\"],null]],[11,\"data-position\",\"bottom\"],[9],[0,\"\\n\"],[4,\"link-to\",[\"talent.profile.detail\"],[[\"class\"],[\"profile-photo-link\"]],{\"statements\":[[0,\"              \"],[1,[27,\"menu/talent-menu-profile\",null,[[\"photoOnly\"],[true]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"          \"],[10],[0,\"\\n        \"],[10],[0,\"\\n        \"],[1,[27,\"menu/slider-menu\",null,[[\"class\",\"menu\",\"selected\"],[\"talent-top-menu-style\",[23,[\"model\",\"sliderMenu\"]],[23,[\"model\",\"selectedMenu\"]]]]],false],[0,\"\\n      \"],[10],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"g-talent-scrollable\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"g-talent-container\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n\"],[0,\"        \"],[7,\"div\"],[11,\"class\",\"col s12\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"model\",\"talent\"]]],null,{\"statements\":[[0,\"            \"],[1,[27,\"talents/talent-information-block\",null,[[\"homepage\",\"talent\"],[[23,[\"model\",\"homepage\"]],[23,[\"model\",\"talent\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n\\n      \"],[1,[21,\"outlet\"],false],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "teelt-frontend/templates/talent/integration.hbs"
    }
  });

  _exports.default = _default;
});