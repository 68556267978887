define("teelt-frontend/components/talents/talent-information-block-date", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    init: function init() {
      this._super.apply(this, arguments);

      _moment.default.locale(this.talent.lang);

      if (!this.talent.template) return;
      Ember.set(this, "templateType", this.talent.template.type);

      switch (this.talent.template.type) {
        case 'end':
          if (this.talent.endDate) {
            Ember.set(this, "date", (0, _moment.default)(this.talent.endDate).format('LLLL').replace(/\d{1,2}\:\d{1,2}(\s)?.*/gm, ''));
          }

          break;

        case 'migration':
          if (this.talent.migrationDate) {
            Ember.set(this, "date", (0, _moment.default)(this.talent.migrationDate).format('LLLL').replace(/\d{1,2}\:\d{1,2}(\s)?.*/gm, ''));
          }

          break;

        case 'assignation':
          if (this.talent.templateAssignationDate) {
            Ember.set(this, "date", (0, _moment.default)(this.talent.templateAssignationDate).format('LLLL').replace(/\d{1,2}\:\d{1,2}(\s)?.*/gm, ''));
          }

          break;

        default:
          if (this.talent.welcomeDate) {
            Ember.set(this, "date", (0, _moment.default)(this.talent.welcomeDate).format('LLLL').replace(/\d{1,2}\:\d{1,2}(\s)?.*/gm, ''));
          }

          break;
      }
    }
  });

  _exports.default = _default;
});