define("teelt-frontend/templates/components/analytics/form-analytic-detail", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9KN4PoBx",
    "block": "{\"symbols\":[],\"statements\":[[0,\"\\n\\n  \"],[7,\"div\"],[11,\"id\",\"form-reports\"],[11,\"class\",\"g-main-section\"],[9],[0,\"\\n\"],[4,\"link-to\",[\"board.analytics\"],[[\"class\"],[\"back foreground-primary-color \"]],{\"statements\":[[0,\"        \"],[7,\"i\"],[11,\"class\",\"material-icons\"],[9],[0,\"close\"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[27,\"eq\",[[23,[\"res\",\"type\"]],\"form\"],null]],null,{\"statements\":[[0,\"      \"],[1,[27,\"analytics/type/form-analytic\",null,[[\"formReportId\",\"complexFilters\"],[[23,[\"formReportId\"]],[23,[\"oldFilters\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[27,\"eq\",[[23,[\"res\",\"type\"]],\"welcome\"],null]],null,{\"statements\":[[0,\"      \"],[1,[27,\"analytics/type/template-analytic\",null,[[\"formReportId\",\"complexFilters\"],[[23,[\"formReportId\"]],[23,[\"oldFilters\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[27,\"eq\",[[23,[\"res\",\"type\"]],\"migration\"],null]],null,{\"statements\":[[0,\"      \"],[1,[27,\"analytics/type/template-analytic\",null,[[\"formReportId\",\"complexFilters\"],[[23,[\"formReportId\"]],[23,[\"oldFilters\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[27,\"eq\",[[23,[\"res\",\"type\"]],\"end\"],null]],null,{\"statements\":[[0,\"      \"],[1,[27,\"analytics/type/template-analytic\",null,[[\"formReportId\",\"complexFilters\"],[[23,[\"formReportId\"]],[23,[\"oldFilters\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[27,\"eq\",[[23,[\"res\",\"type\"]],\"ihm\"],null]],null,{\"statements\":[[0,\"      \"],[1,[27,\"analytics/type/ihm-analytic\",null,[[\"formReportId\",\"complexFilters\"],[[23,[\"formReportId\"]],[23,[\"oldFilters\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n  \"],[10],[0,\"\\n\\n\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "teelt-frontend/templates/components/analytics/form-analytic-detail.hbs"
    }
  });

  _exports.default = _default;
});