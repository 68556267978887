define("teelt-frontend/models/user", ["exports", "ember-data", "moment"], function (_exports, _emberData, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    username: _emberData.default.attr('string'),
    firstname: _emberData.default.attr('string'),
    lastname: _emberData.default.attr('string'),
    proEmail: _emberData.default.attr('string'),
    persoEmail: _emberData.default.attr('string'),
    phoneCode: _emberData.default.attr('string'),
    phoneNumber: _emberData.default.attr('string'),
    position: _emberData.default.attr(),
    team: _emberData.default.attr(),
    experience: _emberData.default.attr(),
    contract: _emberData.default.attr(),
    manager: _emberData.default.attr(),
    welcomeDate: _emberData.default.attr(),
    endDate: _emberData.default.attr(),
    migrationDate: _emberData.default.attr(),
    company: _emberData.default.attr(),
    roles: _emberData.default.attr(),
    template: _emberData.default.attr(),
    templateId: _emberData.default.attr(),
    templateAssignationDate: _emberData.default.attr(),
    hasTools: _emberData.default.attr('boolean'),
    invite: _emberData.default.attr('boolean'),
    campaign: _emberData.default.attr(),
    campaignId: _emberData.default.attr(),
    profilePictureUrl: _emberData.default.attr('string'),
    customFields: _emberData.default.attr(),
    events: _emberData.default.attr(),
    taskValidations: _emberData.default.attr(),
    lang: _emberData.default.attr(),
    address: _emberData.default.attr('string'),
    zipCode: _emberData.default.attr('string'),
    city: _emberData.default.attr('string'),
    trialPeriod: _emberData.default.attr('number'),
    trialPeriodUnit: _emberData.default.attr('string'),
    trialPeriodEndDate: _emberData.default.attr(),
    lastConnection: _emberData.default.attr('date'),
    firstConnection: _emberData.default.attr('date'),
    userForms: _emberData.default.attr(),
    userSignContents: _emberData.default.attr(),
    officeManagerIds: _emberData.default.attr(),
    officeManagers: _emberData.default.attr(),
    buddy: _emberData.default.attr(),
    disableWeeklyReportNotif: _emberData.default.attr('boolean'),
    disableEmailNotif: _emberData.default.attr('boolean'),
    disableSMSNotif: _emberData.default.attr('boolean'),
    disableSlackNotif: _emberData.default.attr('boolean'),
    disableEmailIncidentNotif: _emberData.default.attr('boolean'),
    disableLowOpinionNotif: _emberData.default.attr('boolean'),
    disableMissingDataNotif: _emberData.default.attr('boolean'),
    countEmailIncidentNotif: _emberData.default.attr('number'),
    createdAt: _emberData.default.attr(),
    perimeter: _emberData.default.attr(),
    lastLuccaSync: _emberData.default.attr(),
    syncSlack: _emberData.default.attr('boolean'),
    otherReadableForms: _emberData.default.attr(),
    employeeNumber: _emberData.default.attr(),
    userMeetPeoples: _emberData.default.attr(),
    roleCustomReferents: _emberData.default.attr(),
    name: Ember.computed('firstName', 'lastName', 'username', function () {
      var firstName = this.get('firstname');
      var lastName = this.get('lastname');

      if (firstName && lastName) {
        return "".concat(firstName, " ").concat(lastName);
      } else {
        return this.get('username');
      }
    }),
    validatedAutoTaskNumber: Ember.computed('validatedAutoTasks', function () {
      return this.validatedAutoTasks.length;
    }),
    validatedAutoTasks: Ember.computed('customFields', 'company', function () {
      // company is optional in read mode
      var result = [];
      var company = this.company;
      var customFields = this.customFields;

      if (customFields) {
        var _iteratorNormalCompletion = true;
        var _didIteratorError = false;
        var _iteratorError = undefined;

        try {
          for (var _iterator = customFields[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
            var field = _step.value;

            if (field.user_customfield && field.user_customfield.fullValue) {
              var taskId = field.taskId;

              if (!taskId && company) {
                var _iteratorNormalCompletion2 = true;
                var _didIteratorError2 = false;
                var _iteratorError2 = undefined;

                try {
                  for (var _iterator2 = company.customFields[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
                    var companyField = _step2.value;

                    if (companyField.task && companyField.id == field.id) {
                      taskId = companyField.task.id;
                      break;
                    }
                  }
                } catch (err) {
                  _didIteratorError2 = true;
                  _iteratorError2 = err;
                } finally {
                  try {
                    if (!_iteratorNormalCompletion2 && _iterator2.return != null) {
                      _iterator2.return();
                    }
                  } finally {
                    if (_didIteratorError2) {
                      throw _iteratorError2;
                    }
                  }
                }
              }

              if (taskId) {
                result.push({
                  id: taskId
                });
              }
            }
          }
        } catch (err) {
          _didIteratorError = true;
          _iteratorError = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion && _iterator.return != null) {
              _iterator.return();
            }
          } finally {
            if (_didIteratorError) {
              throw _iteratorError;
            }
          }
        }
      }

      return result;
    }),
    getRemainingTasksOrValidatedTasks: function getRemainingTasksOrValidatedTasks(isRemaining) {
      var roles = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : ['talent'];
      var tasks = [];

      var checkTask = function checkTask(task, taskValidations) {
        if (!taskValidations) return isRemaining;
        var _iteratorNormalCompletion3 = true;
        var _didIteratorError3 = false;
        var _iteratorError3 = undefined;

        try {
          for (var _iterator3 = taskValidations[Symbol.iterator](), _step3; !(_iteratorNormalCompletion3 = (_step3 = _iterator3.next()).done); _iteratorNormalCompletion3 = true) {
            var taskValidation = _step3.value;

            if (task.id === taskValidation.id) {
              return !isRemaining;
            }
          }
        } catch (err) {
          _didIteratorError3 = true;
          _iteratorError3 = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion3 && _iterator3.return != null) {
              _iterator3.return();
            }
          } finally {
            if (_didIteratorError3) {
              throw _iteratorError3;
            }
          }
        }

        return isRemaining;
      };

      if (this.template) {
        if (this.template.periods) {
          var periodIndex = 0;
          var _iteratorNormalCompletion4 = true;
          var _didIteratorError4 = false;
          var _iteratorError4 = undefined;

          try {
            for (var _iterator4 = this.template.periods[Symbol.iterator](), _step4; !(_iteratorNormalCompletion4 = (_step4 = _iterator4.next()).done); _iteratorNormalCompletion4 = true) {
              var period = _step4.value;

              if (period.tasks) {
                var _iteratorNormalCompletion5 = true;
                var _didIteratorError5 = false;
                var _iteratorError5 = undefined;

                try {
                  for (var _iterator5 = period.tasks[Symbol.iterator](), _step5; !(_iteratorNormalCompletion5 = (_step5 = _iterator5.next()).done); _iteratorNormalCompletion5 = true) {
                    var task = _step5.value;

                    if (checkTask(task, this.taskValidations)) {
                      Ember.set(task, 'periodId', period.period.id);
                      Ember.set(task, 'periodIndex', periodIndex);

                      if (roles.includes(task.assign) || roles.includes('ROLE_CONSULT') && task.assign.startsWith('ROLE_CONSULT') || task.signContent && task.signContent.signatories && task.signContent.signatories.indexOf('ROLE_TALENT') >= 0) {
                        tasks.push(task);
                      }
                    }
                  }
                } catch (err) {
                  _didIteratorError5 = true;
                  _iteratorError5 = err;
                } finally {
                  try {
                    if (!_iteratorNormalCompletion5 && _iterator5.return != null) {
                      _iterator5.return();
                    }
                  } finally {
                    if (_didIteratorError5) {
                      throw _iteratorError5;
                    }
                  }
                }
              }

              periodIndex++;
            }
          } catch (err) {
            _didIteratorError4 = true;
            _iteratorError4 = err;
          } finally {
            try {
              if (!_iteratorNormalCompletion4 && _iterator4.return != null) {
                _iterator4.return();
              }
            } finally {
              if (_didIteratorError4) {
                throw _iteratorError4;
              }
            }
          }
        }
      }

      return tasks;
    },
    remainingTalentTasks: Ember.computed('template', 'company', 'taskValidations', 'validatedAutoTasks', function () {
      return this.getRemainingTasksOrValidatedTasks(true);
    }),
    validatedTalentTasks: Ember.computed('template', 'company', 'taskValidations', 'validatedAutoTasks', function () {
      return this.getRemainingTasksOrValidatedTasks(false);
    }),
    remainingTalentTaskNumber: Ember.computed('remainingTalentTasks', function () {
      return this.remainingTalentTasks ? this.remainingTalentTasks.length : 0;
    }),
    remainingRHOrManagerTaskNumber: Ember.computed('template', 'taskValidations', function () {
      return this.getRemainingTasksOrValidatedTasks(true, ['rh', 'manager', 'officeManager', 'administrative', 'adminMRH', 'ROLE_CONSULT']).length;
    }),
    totalTasksTalent: Ember.computed('remainingTalentTasks', 'validatedTalentTasks', function () {
      return this.remainingTalentTasks.length + this.validatedTalentTasks.length;
    }),
    getEvents: function getEvents(inPast) {
      var events = [];

      if (this.events) {
        events = events.concat(this.events);
      }

      if (this.campaign && this.campaign.events) {
        events = events.concat(this.campaign.events);
      }

      var pastEvents = [];

      if (events) {
        var momentDate = (0, _moment.default)();
        var _iteratorNormalCompletion6 = true;
        var _didIteratorError6 = false;
        var _iteratorError6 = undefined;

        try {
          for (var _iterator6 = events[Symbol.iterator](), _step6; !(_iteratorNormalCompletion6 = (_step6 = _iterator6.next()).done); _iteratorNormalCompletion6 = true) {
            var event = _step6.value;

            if (inPast && (0, _moment.default)(event.end).isBefore(momentDate) || !inPast && (0, _moment.default)(event.end).isAfter(momentDate)) {
              pastEvents.push(event);
            }
          }
        } catch (err) {
          _didIteratorError6 = true;
          _iteratorError6 = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion6 && _iterator6.return != null) {
              _iterator6.return();
            }
          } finally {
            if (_didIteratorError6) {
              throw _iteratorError6;
            }
          }
        }
      } // Unicity of events


      var idExists = new Set();
      pastEvents = pastEvents.filter(function (event) {
        if (!idExists.has(event.id)) {
          idExists.add(event.id);
          return true;
        }

        return false;
      });
      this.sortEvents(pastEvents);
      return pastEvents;
    },
    pastEvents: Ember.computed('events', 'campaign', function () {
      return this.getEvents(true);
    }),
    remainingEvents: Ember.computed('events', 'campaign', function () {
      return this.getEvents(false);
    }),
    pastEventNumber: Ember.computed('pastEvents', function () {
      return this.pastEvents.length;
    }),
    sortEvents: function sortEvents(events) {
      var compare = function compare(e1, e2) {
        if (e1.start == e2.start) return 0;
        return e1.start < e2.start ? -1 : 1;
      };

      events.sort(compare);
    },
    totalEvents: Ember.computed('pastEvents', 'remainingEvents', function () {
      var events = [];

      if (this.pastEvents) {
        events = events.concat(this.pastEvents);
      }

      if (this.remainingEvents) {
        events = events.concat(this.remainingEvents);
      }

      this.sortEvents(events);
      return events;
    }),
    totalEventNumber: Ember.computed('totalEvents', function () {
      return this.totalEvents.length;
    }),
    talentProgression: Ember.computed('totalTasksTalent', 'remainingTalentTaskNumber', 'totalEventNumber', 'pastEventNumber', function () {
      if (this.totalEventNumber == 0 && this.totalTasksTalent == 0) {
        return 0;
      }

      var tasksValidated = this.totalTasksTalent - this.remainingTalentTaskNumber;
      return Math.round((this.pastEventNumber + tasksValidated) / (this.totalTasksTalent + this.totalEventNumber) * 100);
    }),
    taskOrEventValidatedNumber: Ember.computed('validatedTalentTasks', 'pastEventNumber', function () {
      var eventNum = this.pastEventNumber ? this.pastEventNumber : 0;
      var tasksNum = this.validatedTalentTasks ? this.validatedTalentTasks.length : 0;
      return eventNum + tasksNum;
    }),
    totalTaskOrEventNumber: Ember.computed('totalTasksTalent', 'totalEventNumber', function () {
      return this.totalTasksTalent + this.totalEventNumber;
    }),
    sponsorCustomField: Ember.computed('customFields', function () {
      if (!this.customFields) return;

      for (var i = 0; i < this.customFields.length; i++) {
        var field = this.customFields[i];

        if (field.codeKey === 'sponsor' && field.user_customfield && field.user_customfield.fullValue) {
          return field;
        }
      }
    }),
    allCustomFieldsInitialized: Ember.computed('customFields', 'company', function () {
      // company.customFields is required here
      if (!this.company.customFields) return this.customFields;
      var customFields = this.customFields || [];
      this.company.customFields.forEach(function (field) {
        var found = false;

        for (var i = 0; i < customFields.length; i++) {
          if (customFields[i].id !== field.id) continue;
          found = true;
          break;
        }

        if (!found) {
          customFields.addObject({
            id: field.id,
            user_customfield: {
              fullValue: ""
            }
          });
        }
      });
      return customFields;
    }),
    hasForms: Ember.computed('template', function () {
      if (this.template && this.template.periods) {
        var _iteratorNormalCompletion7 = true;
        var _didIteratorError7 = false;
        var _iteratorError7 = undefined;

        try {
          for (var _iterator7 = this.template.periods[Symbol.iterator](), _step7; !(_iteratorNormalCompletion7 = (_step7 = _iterator7.next()).done); _iteratorNormalCompletion7 = true) {
            var period = _step7.value;

            if (period.tasks) {
              var _iteratorNormalCompletion8 = true;
              var _didIteratorError8 = false;
              var _iteratorError8 = undefined;

              try {
                for (var _iterator8 = period.tasks[Symbol.iterator](), _step8; !(_iteratorNormalCompletion8 = (_step8 = _iterator8.next()).done); _iteratorNormalCompletion8 = true) {
                  var task = _step8.value;
                  if (task.form && task.form.type !== 'ADMINISTRATIVE') return true;
                }
              } catch (err) {
                _didIteratorError8 = true;
                _iteratorError8 = err;
              } finally {
                try {
                  if (!_iteratorNormalCompletion8 && _iterator8.return != null) {
                    _iterator8.return();
                  }
                } finally {
                  if (_didIteratorError8) {
                    throw _iteratorError8;
                  }
                }
              }
            }
          }
        } catch (err) {
          _didIteratorError7 = true;
          _iteratorError7 = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion7 && _iterator7.return != null) {
              _iterator7.return();
            }
          } finally {
            if (_didIteratorError7) {
              throw _iteratorError7;
            }
          }
        }
      }

      return false;
    }),
    hasAdministrativeForms: Ember.computed('template', function () {
      if (this.template && this.template.periods) {
        var _iteratorNormalCompletion9 = true;
        var _didIteratorError9 = false;
        var _iteratorError9 = undefined;

        try {
          for (var _iterator9 = this.template.periods[Symbol.iterator](), _step9; !(_iteratorNormalCompletion9 = (_step9 = _iterator9.next()).done); _iteratorNormalCompletion9 = true) {
            var period = _step9.value;

            if (period.tasks) {
              var _iteratorNormalCompletion10 = true;
              var _didIteratorError10 = false;
              var _iteratorError10 = undefined;

              try {
                for (var _iterator10 = period.tasks[Symbol.iterator](), _step10; !(_iteratorNormalCompletion10 = (_step10 = _iterator10.next()).done); _iteratorNormalCompletion10 = true) {
                  var task = _step10.value;
                  if (task.form && task.form.type === 'ADMINISTRATIVE') return true;
                }
              } catch (err) {
                _didIteratorError10 = true;
                _iteratorError10 = err;
              } finally {
                try {
                  if (!_iteratorNormalCompletion10 && _iterator10.return != null) {
                    _iterator10.return();
                  }
                } finally {
                  if (_didIteratorError10) {
                    throw _iteratorError10;
                  }
                }
              }
            }
          }
        } catch (err) {
          _didIteratorError9 = true;
          _iteratorError9 = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion9 && _iterator9.return != null) {
              _iterator9.return();
            }
          } finally {
            if (_didIteratorError9) {
              throw _iteratorError9;
            }
          }
        }
      }

      return false;
    }),
    hasMeetPeoples: Ember.computed('template', function () {
      if (this.template && this.template.periods) {
        var _iteratorNormalCompletion11 = true;
        var _didIteratorError11 = false;
        var _iteratorError11 = undefined;

        try {
          for (var _iterator11 = this.template.periods[Symbol.iterator](), _step11; !(_iteratorNormalCompletion11 = (_step11 = _iterator11.next()).done); _iteratorNormalCompletion11 = true) {
            var period = _step11.value;

            if (period.tasks) {
              var _iteratorNormalCompletion12 = true;
              var _didIteratorError12 = false;
              var _iteratorError12 = undefined;

              try {
                for (var _iterator12 = period.tasks[Symbol.iterator](), _step12; !(_iteratorNormalCompletion12 = (_step12 = _iterator12.next()).done); _iteratorNormalCompletion12 = true) {
                  var task = _step12.value;
                  if (task.meetPeople && task.type === 'meetPeople') return true;
                }
              } catch (err) {
                _didIteratorError12 = true;
                _iteratorError12 = err;
              } finally {
                try {
                  if (!_iteratorNormalCompletion12 && _iterator12.return != null) {
                    _iterator12.return();
                  }
                } finally {
                  if (_didIteratorError12) {
                    throw _iteratorError12;
                  }
                }
              }
            }
          }
        } catch (err) {
          _didIteratorError11 = true;
          _iteratorError11 = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion11 && _iterator11.return != null) {
              _iterator11.return();
            }
          } finally {
            if (_didIteratorError11) {
              throw _iteratorError11;
            }
          }
        }
      }

      return false;
    }),
    hasExploreOffices: Ember.computed('template', function () {
      if (this.template && this.template.periods) {
        var _iteratorNormalCompletion13 = true;
        var _didIteratorError13 = false;
        var _iteratorError13 = undefined;

        try {
          for (var _iterator13 = this.template.periods[Symbol.iterator](), _step13; !(_iteratorNormalCompletion13 = (_step13 = _iterator13.next()).done); _iteratorNormalCompletion13 = true) {
            var period = _step13.value;

            if (period.tasks) {
              var _iteratorNormalCompletion14 = true;
              var _didIteratorError14 = false;
              var _iteratorError14 = undefined;

              try {
                for (var _iterator14 = period.tasks[Symbol.iterator](), _step14; !(_iteratorNormalCompletion14 = (_step14 = _iterator14.next()).done); _iteratorNormalCompletion14 = true) {
                  var task = _step14.value;
                  if (task.exploreOffice && task.type === 'exploreOffice') return true;
                }
              } catch (err) {
                _didIteratorError14 = true;
                _iteratorError14 = err;
              } finally {
                try {
                  if (!_iteratorNormalCompletion14 && _iterator14.return != null) {
                    _iterator14.return();
                  }
                } finally {
                  if (_didIteratorError14) {
                    throw _iteratorError14;
                  }
                }
              }
            }
          }
        } catch (err) {
          _didIteratorError13 = true;
          _iteratorError13 = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion13 && _iterator13.return != null) {
              _iterator13.return();
            }
          } finally {
            if (_didIteratorError13) {
              throw _iteratorError13;
            }
          }
        }
      }

      return false;
    }),
    hasTemplate: Ember.computed('template', function () {
      return !!this.template;
    }),
    hasProfileCustomFieldsOtherThanDocuments: Ember.computed('company', function () {
      if (!this.company || !this.company.customFields) return false;
      var companyCustomFields = this.company.customFields;
      return companyCustomFields.filter(function (cf) {
        return cf.section == 'talent-profile' && cf.type !== "file";
      }).length > 0;
    }),
    getDocuments: function getDocuments(filled) {
      if (!this.company || !this.company.customFields || !this.allCustomFieldsInitialized) return false;
      var companyCustomFields = this.company.customFields;
      var documents = [];
      var _iteratorNormalCompletion15 = true;
      var _didIteratorError15 = false;
      var _iteratorError15 = undefined;

      try {
        for (var _iterator15 = companyCustomFields[Symbol.iterator](), _step15; !(_iteratorNormalCompletion15 = (_step15 = _iterator15.next()).done); _iteratorNormalCompletion15 = true) {
          var cf = _step15.value;

          if (cf.section == 'talent-profile' && cf.type == "file") {
            var _iteratorNormalCompletion16 = true;
            var _didIteratorError16 = false;
            var _iteratorError16 = undefined;

            try {
              for (var _iterator16 = this.allCustomFieldsInitialized[Symbol.iterator](), _step16; !(_iteratorNormalCompletion16 = (_step16 = _iterator16.next()).done); _iteratorNormalCompletion16 = true) {
                var customField = _step16.value;

                if (customField.id === cf.id) {
                  if (!filled && !customField.user_customfield.fullValue || filled && customField.user_customfield.fullValue) {
                    documents.push({
                      field: cf,
                      fullValue: customField.user_customfield.fullValue
                    });
                  }

                  break;
                }
              }
            } catch (err) {
              _didIteratorError16 = true;
              _iteratorError16 = err;
            } finally {
              try {
                if (!_iteratorNormalCompletion16 && _iterator16.return != null) {
                  _iterator16.return();
                }
              } finally {
                if (_didIteratorError16) {
                  throw _iteratorError16;
                }
              }
            }
          }
        }
      } catch (err) {
        _didIteratorError15 = true;
        _iteratorError15 = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion15 && _iterator15.return != null) {
            _iterator15.return();
          }
        } finally {
          if (_didIteratorError15) {
            throw _iteratorError15;
          }
        }
      }

      return documents;
    },
    getUnfilledDocuments: Ember.computed('company', 'allCustomFieldsInitialized', function () {
      return this.getDocuments(false);
    }),
    getFilledDocuments: Ember.computed('company', 'allCustomFieldsInitialized', function () {
      return this.getDocuments(true);
    })
  });

  _exports.default = _default;
});