define("teelt-frontend/templates/components/dashboard/user-info-content", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "sgCcDsmR",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"stats-block\"],[9],[0,\"\\n  \"],[1,[27,\"t\",[\"dashboard.user_info.user_count\"],[[\"count\"],[[23,[\"count\"]]]]],false],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"stats-block\"],[9],[0,\"\\n  \"],[1,[27,\"t\",[\"dashboard.user_info.completion_rate\"],[[\"completionRate\"],[[23,[\"completionRate\"]]]]],false],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"stats-block\"],[9],[0,\"\\n  \"],[1,[27,\"t\",[\"dashboard.user_info.connection_rate\"],[[\"connectionRate\"],[[23,[\"connectionRate\"]]]]],false],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "teelt-frontend/templates/components/dashboard/user-info-content.hbs"
    }
  });

  _exports.default = _default;
});