define("teelt-frontend/components/talents/talent-profile-options", ["exports", "teelt-frontend/config/environment", "teelt-frontend/utils/jquery-utils", "jquery"], function (_exports, _environment, _jqueryUtils, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['talent-profile-options'],
    defaultProfileImageUrl: _environment.default.APP.DEFAULT_PROFILE_IMAGE_URL,
    systemStateLink: "https://teelt.statuspage.io/",
    helpCenterLink: "https://teelt.notion.site/Base-de-connaissance-31fc93106d2e450596ae496fe8ba9704",
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    intl: Ember.inject.service(),
    eventBus: Ember.inject.service('event-bus'),
    router: Ember.inject.service(),
    googleCalendar: Ember.inject.service('google-calendar'),
    notify: Ember.inject.service(),
    getGoogleLoginLink: _environment.default.APP.API_HOST + "/googleapis/loginlink",
    getAzureLoginLink: _environment.default.APP.API_HOST + "/azureapis/loginlink",
    init: function init() {
      this._super.apply(this, arguments);

      this.checkExternalCalendars();
    },
    checkExternalCalendars: function checkExternalCalendars() {
      var _this = this;

      if (this.user.company.useGoogleCalendar) {
        this.googleCalendar.isConnected().then(function (connected) {
          Ember.set(_this, "isConnectedGoogle", connected);
        }).catch(function () {});
      }

      if (this.user.company.useAzureCalendar) {
        var url = _environment.default.APP.API_HOST + "/azureapis/calendar/connected";
        return this.ajax.requestPromise(url, "GET").then(function (connected) {
          Ember.set(_this, "isConnectedAzure", connected);
        }).catch(function () {});
      }
    },
    destroy: function destroy() {
      this.eventBus.off('CLICK_EVENT', this, 'checkToCloseModal');

      this._super.apply(this, arguments);
    },
    checkToCloseModal: function checkToCloseModal(e) {
      if (!(0, _jqueryUtils.default)().checkIfClickInElement(e, ["talent-profile-options-modal"])) {
        Ember.set(this, 'showProfileOptions', false);
      }
    },
    didInsertElement: function didInsertElement() {
      var _this2 = this;

      this._super.apply(this, arguments);

      (0, _jquery.default)('.talent-profile-options').css({
        'top': (0, _jquery.default)('#profileImageLink').position().top - (0, _jquery.default)('.talent-profile-options').height() + 100,
        'z-index': 1000
      });
      setTimeout(function () {
        _this2.eventBus.on('CLICK_EVENT', _this2, 'checkToCloseModal');
      }, 300);
    },
    actions: {
      openProfile: function openProfile() {
        Ember.set(this, 'showProfileOptions', false);
        this.router.transitionTo('board.myaccount');
      },
      logout: function logout() {
        var _this3 = this;

        this.session.invalidate().then(function () {
          _this3.router.transitionTo('login.form');
        });
      },
      connectGoogleCalendar: function connectGoogleCalendar() {
        this.ajax.requestWithBodyPromise(this.getGoogleLoginLink, 'POST', "application/json", JSON.stringify({
          redirect: window.location.href
        })).then(function (authLink) {
          var link = authLink.link.replace(/%25STATE%25/g, authLink.token + "_" + false);
          window.location.replace(link);
        });
      },
      disconnectGoogleCalendar: function disconnectGoogleCalendar() {
        var _this4 = this;

        this.ajax.requestWithBodyPromise(this.getGoogleLoginLink, 'DELETE').then(function () {
          Ember.set(_this4, "isConnectedGoogle", false);
        });
      },
      connectAzureApis: function connectAzureApis() {
        this.ajax.requestWithBodyPromise(this.getAzureLoginLink, 'POST', "application/json", JSON.stringify({
          redirect: window.location.href,
          shared: false
        })).then(function (res) {
          window.location.replace(res.link);
        });
      },
      disconnectAzureApis: function disconnectAzureApis() {
        var _this5 = this;

        this.ajax.requestWithBodyPromise(this.getAzureLoginLink, 'DELETE', "application/json", JSON.stringify({
          redirect: window.location.href
        })).then(function (res) {
          Ember.set(_this5, "isConnectedAzure", false);
        });
      },
      forceRefreshGoogleCalendar: function forceRefreshGoogleCalendar() {
        var _this6 = this;

        var path = _environment.default.APP.API_HOST + "/googleapis/calendar";
        this.ajax.requestPromise(path, 'PATCH').then(function () {
          _this6.notify.info(_this6.intl.t('talent_profile_options.agenda_sync'));
        });
      },
      forceRefreshAzureCalendar: function forceRefreshAzureCalendar() {
        var _this7 = this;

        var path = _environment.default.APP.API_HOST + "/azureapis/calendar";
        this.ajax.requestPromise(path, 'PATCH').then(function () {
          _this7.notify.info(_this7.intl.t('talent_profile_options.agenda_sync'));
        });
      }
    }
  });

  _exports.default = _default;
});