define("teelt-frontend/templates/talent/integration/administratives", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NP0pnBtz",
    "block": "{\"symbols\":[],\"statements\":[[0,\"\\n    \\n\"],[1,[27,\"form/forms-list-talent-integration\",null,[[\"forms\",\"talent\",\"forceFormId\",\"adminForms\"],[[23,[\"model\",\"talent\",\"template\",\"forms\"]],[23,[\"model\",\"talent\"]],[23,[\"model\",\"formId\"]],true]]],false],[0,\"\\n\\n\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "teelt-frontend/templates/talent/integration/administratives.hbs"
    }
  });

  _exports.default = _default;
});