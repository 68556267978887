define("teelt-frontend/components/menu/slider-menu", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['slider-menu'],
    doTransition: false,
    router: Ember.inject.service(),
    moveUnderlineToIndex: function moveUnderlineToIndex(index, animate) {
      var transition = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;
      var liElement = this.$('li:nth-child(' + (index + 1) + ')');
      if (!liElement) return;
      var width = liElement.width();
      var offsetLeft = liElement[0] ? liElement[0].offsetLeft : 0;

      if (index > 0) {
        var paddingLeft = liElement.css("padding-left");

        if (paddingLeft) {
          offsetLeft += Number(paddingLeft.substring(0, paddingLeft.length - 2));
        }
      }

      if (transition) {
        if (this.menu.objectAt(index).queryParams) {
          this.router.transitionTo(this.menu.objectAt(index).route, {
            queryParams: this.menu.objectAt(index).queryParams
          });
        } else {
          this.router.transitionTo(this.menu.objectAt(index).route);
        }
      }

      var duration = 0;

      if (animate) {
        duration = 200;
      }

      this.$('.underline').animate({
        'marginLeft': offsetLeft,
        'width': width
      }, duration);
      this.$('.slider-item').removeClass('active');
      this.$('.slider-item-' + index).addClass('active');
    },
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      setTimeout(function () {
        _this.moveUnderlineToIndex(_this.selected, false, false);
      }, 0);
      setTimeout(function () {
        _this.moveUnderlineToIndex(_this.selected, false, false);
      }, 500);
    },
    actions: {
      switchTo: function switchTo(index) {
        Ember.set(this, 'doTransition', true);
        Ember.set(this, 'selected', index);
      }
    },
    selectedObserver: Ember.observer('selected', function () {
      this.moveUnderlineToIndex(this.selected, true, this.doTransition);
      Ember.set(this, 'doTransition', false);
    })
  });

  _exports.default = _default;
});