define("teelt-frontend/templates/talent/integration/resources", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vog+iNTh",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"id\",\"talent-resources-route\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"model\",\"talent\",\"template\"]]],null,{\"statements\":[[4,\"if\",[[27,\"or\",[[23,[\"model\",\"showDocumentResourcesToDownload\"]],[23,[\"model\",\"showDocumentResourcesToConsult\"]]],null]],null,{\"statements\":[[0,\"      \"],[1,[27,\"content/contents-documents-blocks-categories\",null,[[\"section\",\"overrideByOffice\",\"show\",\"toDownload\",\"maxLength\",\"canManage\",\"expandable\",\"forceContentId\"],[[27,\"concat\",[\"template\",[23,[\"model\",\"talent\",\"template\",\"id\"]]],null],true,[23,[\"model\",\"hasDocuments\"]],false,4,false,false,[23,[\"model\",\"contentId\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[10],[0,\"  \\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "teelt-frontend/templates/talent/integration/resources.hbs"
    }
  });

  _exports.default = _default;
});