define("teelt-frontend/components/talents/talent-task-block", ["exports", "moment", "teelt-frontend/config/environment"], function (_exports, _moment, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['talent-task-block'],
    userService: Ember.inject.service(),
    ajax: Ember.inject.service(),
    blockByPeriod: Ember.computed('talentCurrentPeriod', 'task.task', 'task.event', 'index', 'mandatoryTaskIndex', function () {
      if (this.completed) return false;

      if (this.index > this.mandatoryTaskIndex && this.task.task && this.task.task.startOffset != undefined) {
        return true;
      }

      if (this.talentCurrentPeriod != undefined && (this.task.task || this.task.event)) {
        var periodIndex = this.task.task ? this.task.task.periodIndex : this.task.event.periodIndex;

        if (this.talentCurrentPeriod < periodIndex) {
          return true;
        }
      }

      return false;
    }),
    blockByStart: Ember.computed('task.task', 'talent', 'talentCurrentPeriod', 'periods', function () {
      if (this.talent && this.periods && this.task.task && this.task.task.startOffsetUnit && this.task.task.startOffsetDirection) {
        var offset = this.task.task.startOffset;

        if (this.task.task.startOffsetDirection.toLowerCase() == 'before') {
          offset = -1 * offset;
        }

        var triggerDate = this.periods[this.talentCurrentPeriod].triggerDate;

        if (triggerDate == 'assignationDate') {
          triggerDate = 'templateAssignationDate';
        }

        var date = (0, _moment.default)(this.talent[triggerDate]);

        switch (this.task.task.startOffsetUnit.toLowerCase()) {
          case 'hour':
            date.add(offset, 'hours');
            break;

          case 'day':
            date.add(offset, 'days');
            break;

          case 'month':
            date.add(offset, 'months');
            break;
        }

        if (this.task.task.startOffsetUnit.toLowerCase() == 'day' || this.task.task.startOffsetUnit.toLowerCase() == 'month') {
          var now = (0, _moment.default)().startOf('day');
          var dateToCompare = (0, _moment.default)(date).startOf('day');

          if (now.isBefore(dateToCompare)) {
            return true;
          }
        } else {
          var _now = (0, _moment.default)();

          if (_now.isBefore(date)) {
            return true;
          }
        }

        return false;
      }
    }),
    init: function init() {
      this._super.apply(this, arguments);

      if (this.talent) {
        var periods = this.userService.getTalentPeriods(this.talent);
        Ember.set(this, 'periods', periods);
        Ember.set(this, 'talentCurrentPeriod', this.userService.getTalentCurrentPeriod(this.talent, periods));
      }
    },
    actions: {
      trigger: function trigger(task) {
        if (this.blockByPeriod) return;
        if (this.onTriggered) this.onTriggered(task);
      },
      showGallery: function showGallery(task) {
        if (this.onShowGallery) this.onShowGallery(task);
      },
      showExploreOffice: function showExploreOffice(task) {
        if (this.onShowExploreOffice) this.onShowExploreOffice(task);
      },
      readContent: function readContent(task) {
        Ember.set(task.task, 'readContent', true);
        if (this.onTriggered) this.onTriggered(task);
      },
      getSignedDocument: function getSignedDocument(url) {
        var path = _environment.default.APP.API_HOST + "/signcontents/signedDocument?url=" + url;
        this.ajax.requestPromise(path, "GET", null, {
          responseType: 'text'
        }).then(function (data) {
          var binaryString = atob(data);
          var binaryData = new Uint8Array(binaryString.length);

          for (var i = 0; i < binaryString.length; i++) {
            binaryData[i] = binaryString.charCodeAt(i);
          }

          var blob = new Blob([binaryData], {
            type: 'application/pdf'
          });
          var link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          var pattern = /\/documents\/([^/]+)\/download/;
          link.download = pattern.exec(url) ? pattern.exec(url)[1] + ".pdf" : "signature.pdf";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        });
      }
    }
  });

  _exports.default = _default;
});