define("teelt-frontend/templates/components/utils/restore-impersonate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "oqJrbVBW",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,[\"restoreImpersonate\"]]],null,{\"statements\":[[7,\"button\"],[11,\"class\",\"btn btn-primary waves-effect waves-light\"],[3,\"action\",[[22,0,[]],\"restoreImpersonate\"]],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"role\"]]],null,{\"statements\":[[0,\"        \"],[1,[27,\"t\",[\"talent.restore_role\"],[[\"role\"],[[27,\"role/role-custom-name\",[[23,[\"role\"]]],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[1,[27,\"t\",[\"talent.restore_impersonate\"],null],false],[0,\"\\n\"]],\"parameters\":[]}],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "teelt-frontend/templates/components/utils/restore-impersonate.hbs"
    }
  });

  _exports.default = _default;
});