define("teelt-frontend/components/talents/talent-document", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    userService: Ember.inject.service(),
    session: Ember.inject.service(),
    fileUploadService: Ember.inject.service('file-upload'),
    customErrorService: Ember.inject.service('custom-error'),
    progress: 0,
    init: function init() {
      this._super.apply(this, arguments);
    },
    actions: {
      triggerField: function triggerField(field) {
        this.$('#input-' + field.id + ' input.file-container').click();
      },
      onFileUploaded: function onFileUploaded() {
        this.saveTalent();
      },
      openFile: function openFile() {
        var _this = this;

        if (!this.fullValue) return;
        var downloadWaitingUrl = window.location.protocol + "//" + window.location.host + "/downloading";
        var tabOpen = window.open(downloadWaitingUrl, 'newtab');
        this.fileUploadService.getObjectUrl(this.fullValue).then(function (data) {
          var url = data.url + "&inline";
          tabOpen.location = url;
        }).catch(function (err) {
          _this.customErrorService.handleAjaxError(err);
        });
      }
    },
    saveTalent: function saveTalent() {
      // reset roles as array
      this.talent.set('roles', this.session.data.authenticated.user.roles); // reset templateId

      if (this.talent.template) {
        Ember.set(this.talent, 'templateId', this.talent.template.id);
      }

      if (this.talent.campaign) {
        Ember.set(this.talent, "campaignId", this.talent.campaign.id);
      }

      this.talent.save().then(function () {//set(this, 'progress', 0);
      });
    }
  });

  _exports.default = _default;
});