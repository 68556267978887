define("teelt-frontend/components/template/templates-list", ["exports", "teelt-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    router: Ember.inject.service(),
    eventBus: Ember.inject.service(),
    countUrl: _environment.default.APP.API_HOST + "/templates/count",
    classNames: ['templates-list'],
    modelName: 'template',
    modelFilter: {},
    keys: ['type', 'name', 'createdAt', 'users', 'actions'],
    init: function init() {
      this._super.apply(this, arguments);

      Ember.set(this, 'reloadTimestamp', new Date());
      Ember.set(this.modelFilter, 'model', this.isModelList);

      if (this.isModelList) {
        Ember.set(this, 'keys', this.keys.filter(function (key) {
          return key !== 'users';
        }));
      }

      this.eventBus.on('REFRESH_EXPERIENCES', this, 'onExperienceRefresh');
    },
    destroy: function destroy() {
      this.eventBus.off('REFRESH_EXPERIENCES', this, 'onExperienceRefresh');

      this._super.apply(this, arguments);
    },
    onExperienceRefresh: function onExperienceRefresh() {
      Ember.set(this, 'reloadTimestamp', new Date());
    },
    actions: {
      onRowSelected: function onRowSelected(result) {
        this.router.transitionTo('board.experience.experience-edit', result.id);
      }
    }
  });

  _exports.default = _default;
});