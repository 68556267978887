define("teelt-frontend/components/utils/current-role", ["exports", "teelt-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['current-role'],
    session: Ember.inject.service(),
    router: Ember.inject.service(),
    ajax: Ember.inject.service(),
    intl: Ember.inject.service(),
    userService: Ember.inject.service('user-service'),
    eventBus: Ember.inject.service('event-bus'),
    rolecustomService: Ember.inject.service('rolecustom-service'),
    init: function init() {
      this._super.apply(this, arguments);

      this.eventBus.on('ROLELIST_UPDATED', this, 'loadRoles');

      if (!this.currentRole) {
        var currentRole = this.userService.getHighestRole();

        if (currentRole) {
          Ember.set(this, 'currentRole', currentRole);
        }
      }
    },
    destroy: function destroy() {
      this._super.apply(this, arguments);

      this.eventBus.off('ROLELIST_UPDATED', this, 'loadRoles');
    },
    loadRoles: function loadRoles() {
      var _this = this;

      var path = _environment.default.APP.API_HOST + '/users/me/roles';
      this.ajax.requestPromise(path).then(function (roles) {
        var userRoles = roles.filter(function (r) {
          return r != 'ROLE_CONSULT' && r != 'ROLE_USER';
        });
        userRoles = userRoles.concat(['ROLE_USER']);
        var selectRoles = userRoles.map(function (role) {
          var label = _this.rolecustomService.getRoleName(role);

          return {
            key: role,
            label: label
          };
        });
        Ember.set(_this, 'accreditations', selectRoles);
      });
    },
    switchRole: function switchRole() {
      var _this2 = this;

      this.session.authenticate('authenticator:impersonate', this.session.data.authenticated.user.id, this.currentRole).then(function () {
        return _this2.treatRole();
      });
    },
    treatRole: function treatRole() {
      if (this.userService.hasAnyRole(this.session.data.authenticated.user, 'ROLE_ADMIN')) {
        this.router.transitionTo("board.admin.company");
      } else if (this.userService.hasAnyRole(this.session.data.authenticated.user, 'ROLE_RH', 'ROLE_MRH', 'ROLE_MANAGER', 'ROLE_CUSTOM_OFFICE_MANAGER', 'ROLE_ADMINISTRATIVE', 'ROLE_CONSULT')) {
        this.router.transitionTo('board.dashboard');
      } else {
        this.router.transitionTo('talent.home');
      }

      localStorage.setItem('isImpersonateRole', true);
      localStorage.setItem('currentRole', this.currentRole);
      setTimeout(function () {
        document.location.reload();
      }, 200);
    },
    actions: {
      onRoleChanged: function onRoleChanged(value) {
        Ember.set(this, 'currentRole', value.key);
        Ember.set(this, 'showSwitchRoles', false);
        this.switchRole();
      }
    }
  });

  _exports.default = _default;
});