define("teelt-frontend/routes/talent/integration", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    intl: Ember.inject.service(),
    router: Ember.inject.service(),
    afterModel: function afterModel(model, transition) {
      var _this = this;

      Ember.set(model, "router", this.router);
      var sliderMenu = [];

      if (model.talent.templateId) {
        sliderMenu.push({
          name: this.intl.t('integration.resume.my_tasks'),
          route: "talent.integration.tasks"
        });
      }

      if (model.talent.remainingEvents && model.talent.remainingEvents.length) {
        sliderMenu.push({
          name: this.intl.t('integration.resume.my_events'),
          route: "talent.integration.events"
        });
      }

      if (model.talent.template) {
        this.store.query('content', {
          filter: "template" + model.talent.template.id
        }).then(function (contents) {
          if (contents && contents.length && _this.currentModel.sliderMenu.filterBy('route', "talent.integration.resources").length == 0) {
            var i = 0;

            for (i; i < _this.currentModel.sliderMenu.length; i++) {
              if (_this.currentModel.sliderMenu[i].route === "talent.integration.tools" || _this.currentModel.sliderMenu[i].route === "talent.integration.forms") {
                break;
              }
            }

            _this.currentModel.sliderMenu.insertAt(i, {
              name: _this.intl.t('menu.my_resources'),
              route: "talent.integration.resources"
            });

            var _selectedMenu = _this.getSelectedMenuFromTransition(model, transition);

            Ember.set(model, 'selectedMenu', _selectedMenu);
          }
        });
      }

      if (model.talent.hasTools) {
        sliderMenu.push({
          name: this.intl.t('integration.resume.my_tools'),
          route: "talent.integration.tools"
        });
      }

      if (model.talent.hasForms) {
        sliderMenu.push({
          name: this.intl.t('integration.resume.my_forms'),
          route: "talent.integration.forms"
        });
      }

      if (model.talent.hasAdministrativeForms) {
        sliderMenu.push({
          name: this.intl.t('administrative.menu'),
          route: "talent.integration.administratives"
        });
      }

      if (model.talent.company.activeMeetPeople && model.talent.hasMeetPeoples || model.talent.company.activeExploreOffice && model.talent.hasExploreOffices) {
        sliderMenu.push({
          name: this.intl.t('word.ihm'),
          route: "talent.integration.ihm"
        });
      }

      Ember.set(model, 'sliderMenu', sliderMenu);
      var selectedMenu = this.getSelectedMenuFromTransition(model, transition);
      Ember.set(model, 'selectedMenu', selectedMenu);
    },
    getSelectedMenuFromTransition: function getSelectedMenuFromTransition(model, transition) {
      if (transition.targetName === 'talent.integration.index') {
        this.router.transitionTo(model.sliderMenu[0].route);
      }

      var selectedMenu = -1;

      if (transition) {
        for (var i = 0; i < model.sliderMenu.length; i++) {
          var menu = model.sliderMenu[i];

          if (menu.route === transition.targetName) {
            selectedMenu = i;
          }
        }
      }

      return selectedMenu;
    },
    actions: {
      willTransition: function willTransition(transition) {
        var selectedMenu = this.getSelectedMenuFromTransition(this.currentModel, transition);
        Ember.set(this.currentModel, "selectedMenu", selectedMenu);
      }
    }
  });

  _exports.default = _default;
});