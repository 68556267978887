define("teelt-frontend/components/ihm/ihm-list-talent-integration", ["exports", "teelt-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['forms-list-talent-integration', 'talent-card'],
    ajax: Ember.inject.service(),
    router: Ember.inject.service(),
    eventBus: Ember.inject.service(),
    loading: true,
    notify: Ember.inject.service(),
    relatedUserMeetPeoples: [],
    init: function init() {
      this._super.apply(this, arguments);

      this.getResponse();
    },
    extractAllIhmModules: function extractAllIhmModules() {
      this.extractAllMeetPeoples();
      this.extractAllExploreOffices();
    },
    extractAllMeetPeoples: function extractAllMeetPeoples() {
      var meetPeoples = [];

      if (this.talent.template && this.talent.template.periods) {
        var periodIndex = 0;
        var _iteratorNormalCompletion = true;
        var _didIteratorError = false;
        var _iteratorError = undefined;

        try {
          for (var _iterator = this.talent.template.periods[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
            var period = _step.value;

            if (period.tasks) {
              var _iteratorNormalCompletion2 = true;
              var _didIteratorError2 = false;
              var _iteratorError2 = undefined;

              try {
                for (var _iterator2 = period.tasks[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
                  var task = _step2.value;

                  if (task.meetPeople) {
                    Ember.set(task.meetPeople, 'periodId', period.period.id);
                    Ember.set(task.meetPeople, 'periodIndex', periodIndex);
                    Ember.set(task.meetPeople, 'startOffset', task.startOffset);
                    Ember.set(task.meetPeople, 'startOffsetDirection', task.startOffsetDirection);
                    Ember.set(task.meetPeople, 'startOffsetUnit', task.startOffsetUnit);
                    meetPeoples.push(task.meetPeople);
                  }
                }
              } catch (err) {
                _didIteratorError2 = true;
                _iteratorError2 = err;
              } finally {
                try {
                  if (!_iteratorNormalCompletion2 && _iterator2.return != null) {
                    _iterator2.return();
                  }
                } finally {
                  if (_didIteratorError2) {
                    throw _iteratorError2;
                  }
                }
              }
            }

            periodIndex++;
          }
        } catch (err) {
          _didIteratorError = true;
          _iteratorError = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion && _iterator.return != null) {
              _iterator.return();
            }
          } finally {
            if (_didIteratorError) {
              throw _iteratorError;
            }
          }
        }
      }

      Ember.set(this, 'meetPeoples', meetPeoples);
    },
    extractAllExploreOffices: function extractAllExploreOffices() {
      var exploreOffices = [];

      if (this.talent.template && this.talent.template.periods) {
        var periodIndex = 0;
        var _iteratorNormalCompletion3 = true;
        var _didIteratorError3 = false;
        var _iteratorError3 = undefined;

        try {
          for (var _iterator3 = this.talent.template.periods[Symbol.iterator](), _step3; !(_iteratorNormalCompletion3 = (_step3 = _iterator3.next()).done); _iteratorNormalCompletion3 = true) {
            var period = _step3.value;

            if (period.tasks) {
              var _iteratorNormalCompletion4 = true;
              var _didIteratorError4 = false;
              var _iteratorError4 = undefined;

              try {
                for (var _iterator4 = period.tasks[Symbol.iterator](), _step4; !(_iteratorNormalCompletion4 = (_step4 = _iterator4.next()).done); _iteratorNormalCompletion4 = true) {
                  var task = _step4.value;

                  if (task.exploreOffice) {
                    Ember.set(task.exploreOffice, 'periodId', period.period.id);
                    Ember.set(task.exploreOffice, 'periodIndex', periodIndex);
                    Ember.set(task.exploreOffice, 'startOffset', task.startOffset);
                    Ember.set(task.exploreOffice, 'startOffsetDirection', task.startOffsetDirection);
                    Ember.set(task.exploreOffice, 'startOffsetUnit', task.startOffsetUnit);
                    exploreOffices.push(task.exploreOffice);
                  }
                }
              } catch (err) {
                _didIteratorError4 = true;
                _iteratorError4 = err;
              } finally {
                try {
                  if (!_iteratorNormalCompletion4 && _iterator4.return != null) {
                    _iterator4.return();
                  }
                } finally {
                  if (_didIteratorError4) {
                    throw _iteratorError4;
                  }
                }
              }
            }

            periodIndex++;
          }
        } catch (err) {
          _didIteratorError3 = true;
          _iteratorError3 = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion3 && _iterator3.return != null) {
              _iterator3.return();
            }
          } finally {
            if (_didIteratorError3) {
              throw _iteratorError3;
            }
          }
        }
      }

      Ember.set(this, 'exploreOffices', exploreOffices);
    },
    getResponse: function getResponse() {
      var _this = this;

      this.extractAllIhmModules();
      var promises = [];
      this.meetPeoples.forEach(function (meetPeople) {
        if (!meetPeople.task || meetPeople.task.assign === "talent") {
          var path = _environment.default.APP.API_HOST + '/meetpeoples/' + meetPeople.id + '/response/' + _this.talent.id;
          promises.push(_this.ajax.requestPromise(path, 'GET').then(function (res) {
            Ember.set(meetPeople, 'hasBeenLaunched', res.hasBeenLaunched);
            Ember.set(meetPeople, 'participants', res.participants);
            Ember.set(meetPeople, 'meetPeopleImages', res.meetPeopleImages);
          }));
        }
      });
      this.exploreOffices.forEach(function (exploreOffice) {
        if (!exploreOffice.task || exploreOffice.task.assign === "talent") {
          var path = _environment.default.APP.API_HOST + '/exploreoffices/' + exploreOffice.id + '/response/' + _this.talent.id;
          promises.push(_this.ajax.requestPromise(path, 'GET').then(function (res) {
            Ember.set(exploreOffice, 'completed', res.completed);
          }));
        }
      });
      Promise.all(promises).then(function () {
        _this.startImportImageFromLink();

        Ember.set(_this, "loading", false);
      });
    },
    startImportImageFromLink: function startImportImageFromLink() {
      if (this.forceMeetPeopleId) {
        var _iteratorNormalCompletion5 = true;
        var _didIteratorError5 = false;
        var _iteratorError5 = undefined;

        try {
          for (var _iterator5 = this.meetPeoples[Symbol.iterator](), _step5; !(_iteratorNormalCompletion5 = (_step5 = _iterator5.next()).done); _iteratorNormalCompletion5 = true) {
            var meetPeople = _step5.value;

            if (meetPeople.id == this.forceMeetPeopleId) {
              if (meetPeople.hasBeenLaunched || form.mustBeReviewed) {
                this.send('addImageToGallery', meetPeople);
              }

              break;
            }
          }
        } catch (err) {
          _didIteratorError5 = true;
          _iteratorError5 = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion5 && _iterator5.return != null) {
              _iterator5.return();
            }
          } finally {
            if (_didIteratorError5) {
              throw _iteratorError5;
            }
          }
        }
      }
    },
    actions: {
      openExploreOfficeModal: function openExploreOfficeModal(exploreOffice) {
        Ember.set(this, 'exploreOfficeToOpen', exploreOffice);
        Ember.set(this, 'showExploreOfficeModal', true);
      },
      lauchGame: function lauchGame(meetPeople) {
        Ember.set(this, 'meetPeople', meetPeople);
        if (meetPeople.hasBeenLaunched) return;
        Ember.set(this, 'showLauchGameModal', true);
      },
      onGameLauched: function onGameLauched(meetPeople) {
        this.send('onResponseSent');
      },
      showMyContact: function showMyContact(meetPeople) {
        var appearEffect = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
        if (!meetPeople.hasBeenLaunched) return;
        Ember.set(this, 'meetPeople', meetPeople);
        Ember.set(this, 'appearEffect', appearEffect);
        Ember.set(this, 'showModal', true);
        Ember.set(this, 'displayBlock', 'contact');
      },
      addImageToGallery: function addImageToGallery(meetPeople) {
        if (!meetPeople.hasBeenLaunched) return;
        Ember.set(this, 'meetPeople', meetPeople);
        Ember.set(this, 'displayBlock', 'addImages');
        Ember.set(this, 'showModal', true);
      },
      onClickOverlay: function onClickOverlay() {},
      onResponseSent: function onResponseSent() {
        this.getResponse();
        this.eventBus.trigger('FORCE_RELOAD_TALENT');
      },
      showGallery: function showGallery() {
        Ember.set(this, 'showModal', false);
        Ember.set(this, 'displayBlock', null);
        this.eventBus.trigger('OPEN_MEEPEOPLE_GALLERY');
      }
    },
    onMediaProgress: function onMediaProgress(e) {
      if (e.lengthComputable) {
        var progress = Math.ceil(e.loaded / e.total * 100);
        Ember.set(this, 'mediaProgress', progress);
      }
    }
  });

  _exports.default = _default;
});