define("teelt-frontend/templates/board", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xsSyDOk/",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"id\",\"board\"],[9],[0,\"\\n  \"],[1,[27,\"menu/main-menu\",null,[[\"openedMenu\"],[[23,[\"openedMenu\"]]]]],false],[0,\"\\n\\n  \"],[7,\"div\"],[12,\"class\",[28,[\"right-pan \",[27,\"if\",[[23,[\"openedMenu\"]],\"menu-opened\"],null]]]],[9],[0,\"\\n    \"],[1,[21,\"outlet\"],false],[0,\"\\n  \"],[10],[0,\"\\n\\n  \"],[1,[21,\"utils/current-role\"],false],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "teelt-frontend/templates/board.hbs"
    }
  });

  _exports.default = _default;
});