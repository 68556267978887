define("teelt-frontend/routes/application", ["exports", "teelt-frontend/config/environment", "moment"], function (_exports, _environment, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    intl: Ember.inject.service(),
    session: Ember.inject.service(),
    ajax: Ember.inject.service(),
    beforeModel: function beforeModel() {
      // Do not erase : Retrieve CSRF Token
      this.ajax.requestPromise(_environment.default.APP.API_HOST).then(function (result) {
        if (result == 'OK') {
          console.info("API online");
        }
      }); // lazy-load translations

      if (this.session.isAuthenticated && this.session.data.authenticated.user.lang) {
        return this.intl.setLocale([this.session.data.authenticated.user.lang, 'en', 'fr']);
      }

      var browserLocale = navigator.language || navigator.userLanguage;

      if (browserLocale) {
        var lang = browserLocale.split('-')[0];

        if (this.intl.locales.indexOf(lang) > 0) {
          return this.intl.setLocale([lang, 'en', 'fr']);
        }
      }

      return this.intl.setLocale(['en', 'fr']);
    },
    afterModel: function afterModel(model) {
      _moment.default.locale(this.intl.locale[0]);
    }
  });

  _exports.default = _default;
});