define("teelt-frontend/components/form/form-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['form-item'],
    attributeBindings: ['index'],
    maxTovalue: 10,
    selectedContents: Ember.computed('field', function () {
      if (this.field.content && this.field.content.id) {
        return [this.field.content];
      }

      return [];
    }),
    didInsertElement: function didInsertElement() {
      this.$('.select').formSelect();

      if (!this.field.properties) {
        Ember.set(this.field, 'properties', {});
      }

      if (this.field.type === 'opinion_scale' && !this.field.properties.labels) {
        Ember.set(this.field.properties, 'labels', {
          right: '',
          left: ''
        });
      }

      if (this.formType === "QUIZZ") {
        if (this.field.type === 'short_text' && !this.field.possibleResponses) {
          Ember.set(this.field, 'possibleResponses', [{
            value: ''
          }]);
        } else if (this.field.type === 'multiple_choice' && !this.field.possibleResponses) {
          Ember.set(this.field, 'possibleResponses', [{
            value: '',
            isValid: false
          }]);
        }

        if (!this.field.points) {
          Ember.set(this.field, 'points', 1);
        }
      }
    },
    isNumber: function isNumber(value) {
      return !isNaN(Number(value)) && isFinite(value);
    },
    actions: {
      onTypeChange: function onTypeChange(type) {
        Ember.set(this.field, 'type', type);

        if (type === 'file') {
          Ember.set(this.field, 'allowForDownload', this.field.id ? this.field.allowForDownload : true);
        }

        if (type === 'multiple_choice') {
          Ember.set(this.field, 'possibleResponses', [{
            value: ''
          }]);
        } else {
          Ember.set(this.field.properties, 'possibleResponses', undefined);
          Ember.set(this.field.properties, 'allow_multiple_selection', undefined);
        }

        if (type === 'opinion_scale') {
          Ember.set(this.field.properties, 'labels', {
            right: '',
            left: ''
          });
        } else {
          Ember.set(this.field.properties, 'labels', undefined);
          Ember.set(this.field.properties, 'fromValue', undefined);
          Ember.set(this.field.properties, 'toValue', undefined);
        }

        if (this.formType === "QUIZZ") {
          if (type === 'short_text') {
            Ember.set(this.field, 'possibleResponses', [{
              value: ''
            }]);
          } else if (type === 'multiple_choice') {
            Ember.set(this.field, 'possibleResponses', [{
              value: '',
              isValid: false
            }]);
          }
        } else {
          Ember.set(this.field, 'points', undefined);

          if (type !== 'multiple_choice') {
            Ember.set(this.field, 'possibleResponses', undefined);
          }
        }
      },
      delete: function _delete() {
        if (this.onDelete) this.onDelete(this.field);
      },
      duplicate: function duplicate() {
        if (this.onDuplicate) this.onDuplicate(this.field);
      },
      onAddContentFromPopup: function onAddContentFromPopup(contents) {
        var _this = this;

        Ember.set(this.field, 'content', undefined);

        if (contents && contents.length > 0) {
          setTimeout(function () {
            var contentToAdd = contents[0].toJSON({
              includeId: true
            });
            ;
            Ember.set(_this.field, 'content', contentToAdd);
          });
        }
      },
      onNotifyIfLowOpinionChanged: function onNotifyIfLowOpinionChanged() {
        Ember.set(this.field, 'notifyIfLowOpinion', !this.field.notifyIfLowOpinion);

        if (this.field.notifyIfLowOpinion) {
          Ember.set(this.field, 'opinionThreshold', 1);
        }
      },
      onOpinionThresholdChanged: function onOpinionThresholdChanged() {
        if (!this.isNumber(this.field.opinionThreshold)) {
          Ember.set(this.field, 'opinionThreshold', 1);
        }

        if (this.field.opinionThreshold < 1) {
          Ember.set(this.field, 'opinionThreshold', 1);
        }

        if (this.field.opinionThreshold > this.maxToValue - 1) {
          Ember.set(this.field, 'opinionThreshold', this.maxToValue - 1);
        }
      },
      onToChange: function onToChange(toValue) {
        Ember.set(this, "maxToValue", toValue);
        this.send("onOpinionThresholdChanged");
      }
    }
  });

  _exports.default = _default;
});