define("teelt-frontend/templates/components/dashboard/tasks-filters", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "84PTca4V",
    "block": "{\"symbols\":[\"task\"],\"statements\":[[7,\"div\"],[11,\"class\",\"possibles-filters task-filters clickable\"],[9],[0,\"\\n  \"],[7,\"span\"],[12,\"class\",[28,[\"selected-value \",[27,\"if\",[[23,[\"bordered\"]],\"bordered\"],null],\" foreground-primary-color clickable\"]]],[3,\"action\",[[22,0,[]],\"showPossibleFilters\"]],[9],[0,\"\\n    \"],[1,[21,\"filter\"],false],[0,\"\\n\"],[4,\"if\",[[23,[\"showPossibleFilters\"]]],null,{\"statements\":[[0,\"      \"],[7,\"div\"],[11,\"class\",\"material-icons foreground-primary-color\"],[9],[0,\"expand_less\"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[7,\"div\"],[11,\"class\",\"material-icons foreground-primary-color\"],[9],[0,\"expand_more\"],[10],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[10],[0,\"\\n\"],[4,\"if\",[[23,[\"showPossibleFilters\"]]],null,{\"statements\":[[0,\"    \"],[7,\"span\"],[11,\"class\",\"possibles-list\"],[9],[0,\"\\n      \"],[1,[27,\"input\",null,[[\"value\",\"placeholder\",\"input\"],[[23,[\"search\"]],[27,\"t\",[\"word.search\"],null],[27,\"action\",[[22,0,[]],\"onSearchChange\"],null]]]],false],[0,\"\\n\"],[4,\"each\",[[23,[\"allTasks\"]]],null,{\"statements\":[[0,\"        \"],[7,\"div\"],[11,\"class\",\"possibles-value foreground-primary-color\"],[3,\"action\",[[22,0,[]],\"selectFilter\",[22,1,[]]]],[9],[0,\"\\n          \"],[1,[22,1,[\"name\"]],false],[0,\"\\n        \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "teelt-frontend/templates/components/dashboard/tasks-filters.hbs"
    }
  });

  _exports.default = _default;
});