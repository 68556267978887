define("teelt-frontend/components/content/content-form-sign", ["exports", "jquery", "teelt-frontend/config/environment", "pdfjs-dist"], function (_exports, _jquery, _environment, _pdfjsDist) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['content-form-sign'],
    store: Ember.inject.service(),
    router: Ember.inject.service(),
    notify: Ember.inject.service(),
    intl: Ember.inject.service(),
    fileUploadService: Ember.inject.service('file-upload'),
    customErrorService: Ember.inject.service('custom-error'),
    userService: Ember.inject.service(),
    uiUtils: Ember.inject.service("ui-utils"),
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    url: "",
    page: 1,
    roles: [{
      name: "talent",
      filter: "ROLE_TALENT"
    }, {
      name: "manager",
      filter: "ROLE_MANAGER"
    }, {
      name: "rh",
      filter: "ROLE_RH"
    }],
    signs: {},
    newSigner: {},
    canAddSign: Ember.computed('newSign.role', 'newSign.firstname', 'newSign.lastname', 'newSign.email', function () {
      if (this.newSign.role || this.newSign.firstname && this.newSign.firstname.length && this.newSign.lastname && this.newSign.lastname.length && this.newSign.email && this.newSign.email.length) {
        return true;
      }

      return false;
    }),
    overrideKeys: Ember.computed('session.data.authenticated.user', function () {
      if (!this.session.data.authenticated.user) return {};
      return {
        officeManager: this.session.data.authenticated.user.company.officeManagerNameKey,
        manager: this.session.data.authenticated.user.company.managerOverrideKey,
        sponsor: this.session.data.authenticated.user.company.buddyOverrideKey
      };
    }),
    canNext: Ember.computed('fileToSign', 'currentDocumentData', 'content.fileOrigin', 'content.customFieldId', 'content.category', 'content.title', 'fileName', function () {
      if (!this.content.category || !this.content.title) return false;

      if (this.content.fileOrigin == 'common') {
        return this.fileToSign || this.currentDocumenData || this.fileName;
      } else if (this.content.fileOrigin == 'personal') {
        return this.content.customFieldId;
      }
    }),
    roleAlreadyAdded: function roleAlreadyAdded(name) {
      if (this.roles) {
        var _iteratorNormalCompletion = true;
        var _didIteratorError = false;
        var _iteratorError = undefined;

        try {
          for (var _iterator = this.roles[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
            var role = _step.value;
            if (role.name == name) return true;
          }
        } catch (err) {
          _didIteratorError = true;
          _iteratorError = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion && _iterator.return != null) {
              _iterator.return();
            }
          } finally {
            if (_didIteratorError) {
              throw _iteratorError;
            }
          }
        }
      }

      return false;
    },
    init: function init() {
      var _this2 = this;

      this._super.apply(this, arguments);

      if (this.session.data.authenticated.user.company.activeOMRole && !this.roleAlreadyAdded('officeManager')) {
        var roles = this.roles;
        roles.push({
          name: "officeManager",
          filter: "ROLE_CUSTOM_OFFICE_MANAGER"
        });
        Ember.set(this, 'roles', roles);
      }

      if (this.session.data.authenticated.user.company.activeAdministrative && !this.roleAlreadyAdded('administrative')) {
        var _roles = this.roles;

        _roles.push({
          name: "administrative",
          filter: "ROLE_ADMINISTRATIVE"
        });

        Ember.set(this, 'roles', _roles);
      }

      _pdfjsDist.default.GlobalWorkerOptions.workerSrc = '/assets/pdf.worker.min.3.11.174.js';

      if (this.content && !this.content.category) {
        Ember.set(this.content, 'category', {});
      }

      this.loadCategories();

      if (this.content.fileOrigin == 'personal') {
        this.loadPersonalSignInfos();
      }

      if (this.content && this.content.parameters) {
        Ember.set(this, 'signs', JSON.parse(this.content.parameters));
      }

      if (this.content && this.content.documentLink) {
        var urlObject = new URL(this.content.documentLink);
        var filePath = urlObject.pathname;
        var fileName = filePath.split('/').pop();
        Ember.set(this, 'fileName', fileName);
        this.fileUploadService.getObjectBase64(this.content.documentLink).then(function (res) {
          Ember.set(_this2, 'currentDocumenData', res.data);
        }).catch(function (err) {
          _this2.customErrorService.handleAjaxError(err);
        });
      }
    },
    willDestroyElement: function willDestroyElement() {
      if (this.content) {
        this.content.rollbackAttributes();
      }

      this._super.apply(this, arguments);
    },
    loadCategories: function loadCategories() {
      var _this3 = this;

      return this.store.findAll('statics/content-category').then(function (categories) {
        if (categories) {
          Ember.set(_this3, 'categories', categories);

          _this3.uiUtils.initAutocomplete((0, _jquery.default)('#sign-autocomplete-category'), categories, "name", function () {});
        } else {
          Ember.set(_this3, 'categories', []);
        }
      });
    },
    didInsertElement: function didInsertElement() {
      (0, _jquery.default)('#field-type').formSelect();
    },
    preparePDF: function preparePDF() {
      var _this4 = this;

      if (!this.fileToSign && !this.currentDocumenData) return;
      Ember.set(this, 'startPos', false);
      Ember.set(this, 'newSign', {});

      var _this = this;

      if (this.fileToSign) {
        setTimeout(function () {
          var fileReader = new FileReader();
          fileReader.readAsArrayBuffer(_this4.fileToSign);

          fileReader.onload = function () {
            var pdfData = new Uint8Array(this.result);

            _pdfjsDist.default.getDocument(pdfData).promise.then(function (pdf) {
              Ember.set(_this, 'pageNb', pdf.numPages);
              Ember.set(_this, 'pdf', pdf);

              _this.showPage(1);
            });
          };
        });
      } else {
        setTimeout(function () {
          var decodedData = atob(_this4.currentDocumenData);
          var pdfData = new Uint8Array(decodedData.length);

          for (var i = 0; i < decodedData.length; i++) {
            pdfData[i] = decodedData.charCodeAt(i);
          }

          _pdfjsDist.default.getDocument(pdfData).promise.then(function (pdf) {
            Ember.set(_this, 'pageNb', pdf.numPages);
            Ember.set(_this, 'pdf', pdf);

            _this.showPage(1);
          });
        });
      }
    },
    showPage: function showPage(nb) {
      var _this5 = this;

      if (!nb) {
        nb = 1;
      }

      var pdfContainer = document.getElementById("pdf-container");
      var oldCanvas = pdfContainer.querySelector("canvas");

      if (oldCanvas) {
        pdfContainer.removeChild(oldCanvas);
      }

      var zoom = (0, _jquery.default)('body').css("zoom");

      if (!zoom) {
        zoom = 1.0;
      }

      Ember.set(this, 'currentPage', nb);
      this.pdf.getPage(nb).then(function (page) {
        var viewportWidth = page.getViewport({
          scale: 1.0
        }).width;
        var viewportHeight = page.getViewport({
          scale: 1.0
        }).height;
        var pdfContainerWidth = pdfContainer.clientWidth;
        var scale = pdfContainerWidth / viewportWidth;
        Ember.set(_this5, "scale", scale);
        var viewport = page.getViewport({
          scale: scale
        });
        var canvas = document.createElement('canvas');
        var context = canvas.getContext('2d');
        canvas.id = 'pdf-canvas';
        canvas.width = viewport.width;
        canvas.height = viewport.height;
        pdfContainer.appendChild(canvas);
        page.render({
          canvasContext: context,
          viewport: viewport
        }).promise.then(function () {
          var pageSigns = _this5.signs['page' + nb];
          Ember.set(_this5, 'pageSigns', undefined);
          Ember.set(_this5, 'pageSigns', pageSigns);

          if (pageSigns) {
            for (var i = 0; i < pageSigns.length; i++) {
              var sign = pageSigns[i];
              var x = sign.x;
              var y = sign.y;
              var color = "#000000";

              if (sign.who.role) {
                switch (sign.who.role) {
                  case "ROLE_TALENT":
                    color = "#4DB9FA";
                    break;

                  case "ROLE_MANAGER":
                    color = "#7DDFC3";
                    break;

                  case "ROLE_RH":
                    color = "#FFCF5C";
                    break;

                  case "ROLE_CUSTOM_OFFICE_MANAGER":
                    color = "#7CEBED";
                    break;

                  case "ROLE_ADMINISTRATIVE":
                    color = "#FFC7A6";
                    break;
                }
              }

              context.strokeStyle = color;
              context.lineWidth = 3;
              context.strokeRect(x * _this5.scale, (viewportHeight - y) * _this5.scale - 25, 200, 50);
              context.font = "20px Arial";
              context.textAlign = "center";
              context.textBaseline = "middle";
              context.fillText(i + 1, x * _this5.scale + 10, (viewportHeight - y) * _this5.scale - 10);
            }
          }
        });
      });
    },
    checkPage1: function checkPage1() {
      var errors = {};
      var ok = true;

      if (!this.content.category || !this.content.category.name) {
        Ember.set(errors, 'category', true);
        ok = false;
      }

      if (!this.content.title) {
        Ember.set(errors, 'title', true);
        ok = false;
      }

      if (!this.fileToSign && !this.currentDocumenData) {
        Ember.set(errors, 'pdf', true);
        ok = false;
      }

      Ember.set(this, 'errors', errors);
      return ok;
    },
    activeClickListenerOnCanvas: function activeClickListenerOnCanvas() {
      var _this6 = this;

      var pageSigns = this.signs['page' + this.currentPage];

      if (!pageSigns) {
        pageSigns = [];
        Ember.set(this.signs, 'page' + this.currentPage, pageSigns);
      }

      var canvas = document.getElementById('pdf-canvas');
      var context = canvas.getContext('2d');
      canvas.addEventListener('click', function (event) {
        if (!_this6.newSign) return;
        var zoom = (0, _jquery.default)('body').css("zoom");

        if (!zoom) {
          zoom = 1.0;
        }

        var x = Math.max(0, event.offsetX / zoom / _this6.scale - 100);
        var y = Math.max(0, event.offsetY / zoom / _this6.scale + 25);

        _this6.pdf.getPage(_this6.currentPage).then(function (page) {
          var viewport = page.getViewport({
            scale: 1.0
          });
          y = Math.max(0, viewport.height - y);
          x = Math.min(Math.max(-100, x), viewport.width - 200);
          y = Math.min(Math.max(25, y), viewport.height - 50);
          pageSigns.push({
            x: Math.round(x),
            y: Math.round(y),
            who: {
              role: _this6.newSign.role,
              firstname: _this6.newSign.firstname,
              lastname: _this6.newSign.lastname,
              email: _this6.newSign.email
            }
          });
          Ember.set(_this6.signs, 'page' + _this6.currentPage, pageSigns);
          Ember.set(_this6, 'newSign', undefined);
          Ember.set(_this6, 'startPos', false);

          _this6.showPage(_this6.currentPage);
          /*
                  var canvasRect = canvas.getBoundingClientRect();
                  console.log('EVENT', event);
                  console.log('RECT', canvasRect);
                  console.log('OFFSET', $('#pdf-canvas').offset())
          
          
                  context.fillStyle = "#FF0000"
                  context.fillRect(x * this.scale, (viewport.height - y) * this.scale - 25, 100, 25);
          
                  */

        });
      });
    },
    activeMouseTracker: function activeMouseTracker() {
      setTimeout(function () {
        var container = document.getElementById('pdf-container');
        var tracker = document.getElementById('mouseTracker');
        container.addEventListener('mousemove', function (e) {
          var x = e.clientX - container.getBoundingClientRect().left - 100;
          var y = e.clientY - container.getBoundingClientRect().top;
          tracker.style.left = x + "px";
          tracker.style.top = y + "px";
        });
      }, 200);
    },
    saveContent: function saveContent() {
      var _this7 = this;

      if (this.blockSave) return;
      Ember.set(this, 'blockSave', true);
      var content = this.content;

      if (content.fileOrigin == 'personal') {
        var pageX = this.personalSigns.pageX.filter(function (sign) {
          return sign.who && (sign.who.role || sign.who.checked);
        });
        Ember.set(this.personalSigns, 'pageX', pageX);
        Ember.set(content, 'parameters', JSON.stringify(this.personalSigns));
        content.save().then(function (content) {
          if (_this7.onFinish) {
            _this7.onFinish(content);
          }
        });
      } else if (this.fileToSign) {
        var customName = content.category.name + '_' + content.title;
        this.fileUploadService.signAndUpload(this.fileToSign, true, function (e) {
          return _this7.onContentFileProgress(e);
        }, customName).then(function (fileUrl) {
          Ember.set(content, 'documentLink', fileUrl);
          Ember.set(content, 'parameters', JSON.stringify(_this7.signs));
          content.save().then(function (content) {
            if (_this7.onFinish) {
              _this7.onFinish(content);
            }
          });
        }).finally(function () {
          Ember.set(_this7, 'blockSave', false);
        });
      } else {
        Ember.set(content, 'parameters', JSON.stringify(this.signs));
        content.save().then(function (content) {
          if (_this7.onFinish) {
            _this7.onFinish(content);
          }
        });
      }
    },
    loadPersonalSignInfos: function loadPersonalSignInfos() {
      var _this8 = this;

      this.ajax.requestPromise(_environment.default.APP.API_HOST + '/custom-fields?type=file', 'GET').then(function (customFields) {
        Ember.set(_this8, "customFileFields", [{
          id: 0
        }].concat(customFields));
        Ember.set(_this8.content, 'fileOrigin', 'personal');
        setTimeout(function () {
          _this8.$('#customFieldFiles').select2();
        });
      });

      if (!this.personalSigns && this.content.parameters) {
        Ember.set(this, 'personalSigns', JSON.parse(this.content.parameters));
      } else if (!this.personalSigns) {
        Ember.set(this, 'personalSigns', {
          pageX: []
        });
      }

      var pageX = this.personalSigns.pageX;
      var _iteratorNormalCompletion2 = true;
      var _didIteratorError2 = false;
      var _iteratorError2 = undefined;

      try {
        for (var _iterator2 = this.roles[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
          var role = _step2.value;
          Ember.set(role, 'selected', false);
          var _iteratorNormalCompletion4 = true;
          var _didIteratorError4 = false;
          var _iteratorError4 = undefined;

          try {
            for (var _iterator4 = pageX[Symbol.iterator](), _step4; !(_iteratorNormalCompletion4 = (_step4 = _iterator4.next()).done); _iteratorNormalCompletion4 = true) {
              var sign = _step4.value;

              if (sign.who && sign.who.role == role.filter) {
                Ember.set(role, 'selected', true);
              }
            }
          } catch (err) {
            _didIteratorError4 = true;
            _iteratorError4 = err;
          } finally {
            try {
              if (!_iteratorNormalCompletion4 && _iterator4.return != null) {
                _iterator4.return();
              }
            } finally {
              if (_didIteratorError4) {
                throw _iteratorError4;
              }
            }
          }
        }
      } catch (err) {
        _didIteratorError2 = true;
        _iteratorError2 = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion2 && _iterator2.return != null) {
            _iterator2.return();
          }
        } finally {
          if (_didIteratorError2) {
            throw _iteratorError2;
          }
        }
      }

      var otherPersonalSigns = [];
      var _iteratorNormalCompletion3 = true;
      var _didIteratorError3 = false;
      var _iteratorError3 = undefined;

      try {
        for (var _iterator3 = this.personalSigns.pageX[Symbol.iterator](), _step3; !(_iteratorNormalCompletion3 = (_step3 = _iterator3.next()).done); _iteratorNormalCompletion3 = true) {
          var _sign = _step3.value;

          if (_sign.who && _sign.who.email) {
            var who = _sign.who;
            who.checked = true;
            otherPersonalSigns.push(who);
          }
        }
      } catch (err) {
        _didIteratorError3 = true;
        _iteratorError3 = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion3 && _iterator3.return != null) {
            _iterator3.return();
          }
        } finally {
          if (_didIteratorError3) {
            throw _iteratorError3;
          }
        }
      }

      Ember.set(this, 'otherPersonalSigns', otherPersonalSigns);
    },
    actions: {
      deleteRole: function deleteRole(role) {
        Ember.set(role, 'value', false);
      },
      previous: function previous() {
        Ember.set(this, 'page', this.page - 1);

        if (this.page <= 0) {
          this.onPrevious();
        }
      },
      next: function next() {
        if (this.content.fileOrigin == 'personal') {
          this.saveContent();
          return;
        }

        if (this.page == 2) {
          this.saveContent();
          return;
        }

        if (this.page == 1) {
          var ok = this.checkPage1();
          if (!ok) return;
        }

        Ember.set(this, 'page', this.page + 1);

        if (this.page == 2) {
          this.preparePDF();
        }
      },
      onCategoryChanged: function onCategoryChanged() {},
      onContentFileChanged: function onContentFileChanged(file) {
        if (!file) return;
        Ember.set(this, 'fileToSign', file);
      },
      nextPage: function nextPage() {
        if (this.currentPage < this.pageNb) {
          this.showPage(this.currentPage + 1);
        }
      },
      previousPage: function previousPage() {
        if (this.currentPage > 1) {
          this.showPage(this.currentPage - 1);
        }
      },
      newSign: function newSign() {
        Ember.set(this, 'newSign', {});
        Ember.set(this, 'startPos', false);
      },
      selectRole: function selectRole(role) {
        if (this.newSign.role == role.filter) {
          Ember.set(this.newSign, 'role', undefined);
        } else {
          Ember.set(this.newSign, 'role', role.filter);
          Ember.set(this.newSign, 'firstname', undefined);
          Ember.set(this.newSign, 'lastname', undefined);
          Ember.set(this.newSign, 'email', undefined);
        }
      },
      startSignPos: function startSignPos() {
        if (!this.canAddSign) return;
        Ember.set(this, 'startPos', true);
        this.activeClickListenerOnCanvas();
        this.activeMouseTracker();
      },
      closeNewSign: function closeNewSign() {
        Ember.set(this, 'startPos', false);
        Ember.set(this, 'newSign', undefined);
      },
      removeSign: function removeSign(index) {
        var pageSigns = this.signs['page' + this.currentPage];

        if (pageSigns) {
          pageSigns.splice(index, 1);
        }

        Ember.set(this.signs, 'page' + this.currentPage, pageSigns);
        this.showPage(this.currentPage);
      },
      selectFileType: function selectFileType(fileType) {
        if (fileType == 'common') {
          Ember.set(this.content, 'fileOrigin', 'common');
        } else {
          this.loadPersonalSignInfos();
        }
      },
      onCustomFieldFileChange: function onCustomFieldFileChange(data) {
        if (data) {
          Ember.set(this.content, 'customFieldId', Number(data));
        } else {
          Ember.set(this.content, 'customFieldId', undefined);
        }
      },
      onPersonalSignRoleSelect: function onPersonalSignRoleSelect(role) {
        var pageSigns = this.personalSigns.pageX;
        var present = false;
        var _iteratorNormalCompletion5 = true;
        var _didIteratorError5 = false;
        var _iteratorError5 = undefined;

        try {
          for (var _iterator5 = pageSigns[Symbol.iterator](), _step5; !(_iteratorNormalCompletion5 = (_step5 = _iterator5.next()).done); _iteratorNormalCompletion5 = true) {
            var sign = _step5.value;

            if (sign.who && sign.who.role == role) {
              present = true;
              break;
            }
          }
        } catch (err) {
          _didIteratorError5 = true;
          _iteratorError5 = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion5 && _iterator5.return != null) {
              _iterator5.return();
            }
          } finally {
            if (_didIteratorError5) {
              throw _iteratorError5;
            }
          }
        }

        if (present) {
          pageSigns = pageSigns.filter(function (sign) {
            return !(sign.who && sign.who.role == role);
          });
          Ember.set(this.personalSigns, 'pageX', pageSigns);
        } else {
          pageSigns.push({
            who: {
              role: role
            }
          });
        }
      },
      addOtherSign: function addOtherSign() {
        var _this9 = this;

        var email = this.newSigner.email;

        if (this.otherPersonalSigns) {
          var _iteratorNormalCompletion6 = true;
          var _didIteratorError6 = false;
          var _iteratorError6 = undefined;

          try {
            for (var _iterator6 = this.otherPersonalSigns[Symbol.iterator](), _step6; !(_iteratorNormalCompletion6 = (_step6 = _iterator6.next()).done); _iteratorNormalCompletion6 = true) {
              var otherSign = _step6.value;
              if (otherSign.email == email) return;
            }
          } catch (err) {
            _didIteratorError6 = true;
            _iteratorError6 = err;
          } finally {
            try {
              if (!_iteratorNormalCompletion6 && _iterator6.return != null) {
                _iterator6.return();
              }
            } finally {
              if (_didIteratorError6) {
                throw _iteratorError6;
              }
            }
          }
        }

        var otherPersonalSigns = this.otherPersonalSigns;
        var who = {
          firstname: this.newSigner.firstname,
          lastname: this.newSigner.lastname,
          email: this.newSigner.email,
          checked: true
        };
        otherPersonalSigns.push(who);
        Ember.set(this, 'otherPersonalSigns', undefined);
        setTimeout(function () {
          Ember.set(_this9, 'otherPersonalSigns', otherPersonalSigns);
        });
        var pageSigns = this.personalSigns.pageX;
        pageSigns.push({
          who: who
        });
        Ember.set(this.newSigner, 'firstname', '');
        Ember.set(this.newSigner, 'lastname', '');
        Ember.set(this.newSigner, 'email', '');
      }
    },
    validate: function validate() {
      this.resetErrors();

      if (!this.content.category || !this.content.category.name) {
        this.notify.alert(this.intl.t('content.alerts.select_category'), {
          closeAfter: 5000
        });
        return false;
      }

      return true;
    },
    resetErrors: function resetErrors() {
      Ember.set(this, 'titleIsNull', undefined);
    },
    onContentFileProgress: function onContentFileProgress(e) {}
  });

  _exports.default = _default;
});