define("teelt-frontend/templates/components/dashboard/email-not-sent-alert", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "c5nVPsvG",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"db-element\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"header\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"title\"],[9],[1,[27,\"t\",[\"dashboard.mail_not_send.title\"],null],false],[10],[0,\"\\n    \"],[1,[27,\"utils/btn-info\",null,[[\"info\"],[[27,\"t\",[\"dashboard.satisfaction_alert.info\"],null]]]],false],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"content\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"loading\"]]],null,{\"statements\":[[0,\"      \"],[7,\"div\"],[11,\"class\",\"loading-container\"],[9],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"loading\"],[9],[0,\"\\n          \"],[1,[21,\"icon/loading-svg\"],false],[0,\"\\n        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[7,\"div\"],[11,\"class\",\"desc\"],[9],[1,[27,\"t\",[\"dashboard.mail_not_send.desc\"],null],false],[10],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"count-data\"],[9],[1,[21,\"count\"],false],[10],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[10],[0,\"\\n\"],[4,\"if\",[[23,[\"count\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"footer\"],[9],[0,\"\\n      \"],[7,\"button\"],[11,\"class\",\"btn waves-effect waves-light background-primary-color\"],[3,\"action\",[[22,0,[]],\"gotoList\"]],[9],[0,\"\\n        \"],[1,[27,\"t\",[\"word.see_list\"],null],false],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "teelt-frontend/templates/components/dashboard/email-not-sent-alert.hbs"
    }
  });

  _exports.default = _default;
});