define("teelt-frontend/routes/login/form", ["exports", "teelt-frontend/config/environment", "jquery"], function (_exports, _environment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    intl: Ember.inject.service(),
    session: Ember.inject.service(),
    userService: Ember.inject.service('user-service'),
    ajax: Ember.inject.service(),
    store: Ember.inject.service('session'),
    getBackground: Ember.inject.service('get-background'),
    customErrorService: Ember.inject.service('custom-error'),
    beforeModel: function beforeModel(transition) {
      if (this.session.data.authenticated.user) {
        this.goToAuthenticatedSection();
      } else {
        this.cleanAuthentication();
      }
    },
    model: function model() {
      return {
        errors: {},
        emailConstraints: this.getEmailValidation(),
        passwordConstraints: this.getPasswordValidation()
      };
    },
    afterModel: function afterModel(model) {
      var _this = this;

      var subdomain = window.location.host.split('.')[1] ? window.location.host.split('.')[0] : 'default';
      var path = _environment.default.APP.API_HOST + "/companies/customizations/domain?domain=" + subdomain;
      this.ajax.requestPromise(path, 'GET').then(function (customizations) {
        Ember.set(model, 'customizations', customizations);

        _this.getBackground.getBackground(model, customizations);
      });
    },
    cleanAuthentication: function cleanAuthentication() {
      this.session.invalidate();
      localStorage.clear();
    },
    getEmailValidation: function getEmailValidation() {
      return [{
        message: this.intl.t('login.errors.fillin_username'),
        validate: function validate(inputValue) {
          return !!inputValue;
        }
      }];
    },
    getPasswordValidation: function getPasswordValidation() {
      return [{
        message: this.intl.t('login.errors.fillin_password'),
        validate: function validate(inputValue) {
          return !!inputValue;
        }
      }];
    },
    goToAuthenticatedSection: function goToAuthenticatedSection() {
      var _this2 = this;

      if (this.userService.hasAnyRole(this.session.data.authenticated.user, 'ROLE_ADMIN')) {
        this.transitionTo("board.admin.company");
      } else if (this.userService.isManager(this.session.data.authenticated.user)) {
        this.transitionTo("board.talents");
      } else if (this.userService.hasAnyRole(this.session.data.authenticated.user, 'ROLE_ADMINISTRATIVE')) {
        this.transitionTo("board.talents");
      } else if (this.userService.hasAnyRole(this.session.data.authenticated.user, 'ROLE_RH', 'ROLE_CUSTOM_OFFICE_MANAGER')) {
        this.transitionTo("board.talents");
      } else {
        this.transitionTo("talent.home");
      }

      setTimeout(function () {
        var currentUrl = Ember.getOwner(_this2).lookup('router:main').get('currentURL');

        if (currentUrl == "/login/form") {
          _this2.customErrorService.errorWithIcon(_this2.intl.t('login.errors.mutliple_open_windows'), 10000);
        }
      }, 1000);
    },
    actions: {
      submit: function submit() {
        var _this3 = this;

        if (!this.validateForm()) return;
        var model = this.currentModel;
        this.get('session').authenticate('authenticator:basic', model.username, model.password).then(function () {
          _this3.goToAuthenticatedSection();
        }, function (err) {
          if (err.status === 401) {
            Ember.set(_this3.currentModel.errors, 'form', _this3.intl.t('login.errors.error_401'));
          } else if (err.status === 403) {
            Ember.set(_this3.currentModel.errors, 'form', _this3.intl.t('login.errors.error_403'));
          } else {
            Ember.set(_this3.currentModel.errors, 'form', _this3.intl.t('login.errors.error_500'));
          }
        });
      },
      connectSSO: function connectSSO() {
        var _this4 = this;

        var model = this.currentModel;
        if (!model.ssoEmail) return;
        var path = _environment.default.APP.API_HOST + "/users/me/sso/verify?samlType=saml-" + model.customizations.companyId + "&username=" + encodeURIComponent(model.ssoEmail);
        this.ajax.requestPromise(path, 'GET').then(function () {
          var front = window.location.protocol + "//" + window.location.host;
          document.location.href = _environment.default.APP.SSO_HOST + "/sso/redirect?api=" + encodeURIComponent(_environment.default.APP.API_HOST) + "&front=" + encodeURIComponent(front) + "&companyId=" + model.customizations.companyId;
        }).catch(function (err) {
          Ember.set(_this4.currentModel.errors, 'sso', true);
        });
      },
      onPasswordInput: function onPasswordInput(value) {
        var eye = document.getElementById("login-password-eye");

        if (value != '') {
          eye.style.visibility = "visible";
        } else {
          eye.style.visibility = "hidden";
          var input = document.getElementById("password");
          input.type = "password";
          eye.textContent = "visibility";
        }
      },
      togglePasswordVisibility: function togglePasswordVisibility() {
        var eye = document.getElementById('login-password-eye');
        var input = document.getElementById('password');

        if (eye.textContent === "visibility") {
          eye.textContent += "_off";
          input.type = "text";
        } else {
          eye.textContent = eye.textContent.replace("_off", "");
          input.type = "password";
        }
      }
    },
    validateForm: function validateForm() {
      var validated = true; // let model = this.currentModel;

      Ember.set(this.currentModel.errors, 'form', undefined);
      return validated;
    }
  });

  _exports.default = _default;
});