define("teelt-frontend/components/utils/restore-impersonate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['restore-impersonate'],
    session: Ember.inject.service(),
    router: Ember.inject.service(),
    userService: Ember.inject.service('user-service'),
    eventBus: Ember.inject.service('event-bus'),
    rolecustomService: Ember.inject.service('rolecustom-service'),
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      this.eventBus.on('VERIFY_RESTORE_IMPERSONATE', this, function () {
        _this.verifyRestoreImpersonate();
      });
      this.verifyRestoreImpersonate();
    },
    destroy: function destroy() {
      this.eventBus.off('VERIFY_RESTORE_IMPERSONATE', this);

      this._super.apply(this, arguments);
    },
    verifyRestoreImpersonate: function verifyRestoreImpersonate() {
      var impersonate = localStorage.getItem("restoreImpersonate");

      if (impersonate) {
        Ember.set(this, "restoreImpersonate", impersonate);
        var currentRole = localStorage.getItem("currentRole");

        if (currentRole) {
          Ember.set(this, 'role', currentRole);
        }
      }
    },
    actions: {
      restoreImpersonate: function restoreImpersonate() {
        var _this2 = this;

        localStorage.removeItem("restoreImpersonate");
        localStorage.removeItem("currentRole");
        localStorage.removeItem('isImpersonateRole');
        this.session.authenticate('authenticator:impersonateRestore').then(function () {
          if (_this2.userService.hasAnyRole(_this2.session.data.authenticated.user, 'ROLE_ADMIN')) {
            _this2.router.transitionTo("board.admin.company");
          } else if (_this2.userService.hasAnyRole(_this2.session.data.authenticated.user, 'ROLE_RH', 'ROLE_MANAGER', 'ROLE_CUSTOM_OFFICE_MANAGER', 'ROLE_ADMINISTRATIVE', 'ROLE_CONSULT')) {
            if (_this2.router.currentRouteName === "board.talents") {
              _this2.eventBus.trigger('RELOAD_TALENTS');
            } else {
              _this2.router.transitionTo("board.talents");
            }
          } else {
            _this2.router.transitionTo("talent.home");
          }

          setTimeout(function () {
            document.location.reload();
          }, 200);
        }, function (err) {
          _this2.session.invalidate().then(function () {
            _this2.router.transitionTo('login.form');
          });
        });
        Ember.set(this, "restoreImpersonate", null);
        Ember.set(this, "role", null);
      }
    }
  });

  _exports.default = _default;
});