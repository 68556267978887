define("teelt-frontend/components/settings/customfield-list", ["exports", "teelt-frontend/config/environment", "teelt-frontend/utils/jquery-utils"], function (_exports, _environment, _jqueryUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['customfield-list'],
    ajax: Ember.inject.service(),
    eventBus: Ember.inject.service(),
    notify: Ember.inject.service(),
    intl: Ember.inject.service(),
    session: Ember.inject.service(),
    step: 'list',
    companyUserDefaultField: {},
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      this.eventBus.on('RELOAD_CUSTOMFIELDS', this, 'loadCustomFields');
      this.ajax.requestPromise(_environment.default.APP.API_HOST + '/companies/required-user-fields', 'GET').then(function (response) {
        Ember.set(_this, "companyUserDefaultField", response);
      });
      this.updateRoleCustomReferents();
    },
    updateRoleCustomReferents: function updateRoleCustomReferents() {
      var _this2 = this;

      Ember.set(this, 'referentRoleCustoms', []);
      var roleCustomPath = _environment.default.APP.API_HOST + "/roles?consultRoles=true&referent=true";
      this.ajax.requestPromise(roleCustomPath, 'GET').then(function (result) {
        if (result) {
          Ember.set(_this2, 'referentRoleCustoms', result);
        }
      });
    },
    destroy: function destroy() {
      this.eventBus.off('RELOAD_CUSTOMFIELDS', this, 'loadCustomFields');
      this.eventBus.off('CLICK_EVENT', this, 'checkToCloseModal');
      this.clearDrake();

      this._super.apply(this, arguments);
    },
    didInsertElement: function didInsertElement() {
      var _this3 = this;

      this._super.apply(this, arguments);

      this.loadCustomFields();
      setTimeout(function () {
        _this3.eventBus.on('CLICK_EVENT', _this3, 'checkToCloseModal');
      }, 300);
    },
    checkToCloseModal: function checkToCloseModal(e) {
      if (!(0, _jqueryUtils.default)().checkIfClickInElement(e, ["module-actions-dialog"])) {
        if (this.showActions) {
          Ember.set(this, 'showActions', false);
        }
      }
    },
    clearDrake: function clearDrake() {
      if (this.drake) {
        this.drake.destroy();
        Ember.set(this, 'drake', undefined);
      }
    },
    initDrake: function initDrake() {
      var _this4 = this;

      setTimeout(function () {
        _this4.clearDrake();

        var self = _this4;

        var containersQuery = _this4.$(".customfields .boxlist");

        var containers = [];
        var _iteratorNormalCompletion = true;
        var _didIteratorError = false;
        var _iteratorError = undefined;

        try {
          for (var _iterator = containersQuery[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
            var container = _step.value;
            containers.push(container);
          }
        } catch (err) {
          _didIteratorError = true;
          _iteratorError = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion && _iterator.return != null) {
              _iterator.return();
            }
          } finally {
            if (_didIteratorError) {
              throw _iteratorError;
            }
          }
        }

        var drake = dragula({
          containers: containers,
          revertOnSpill: false,
          accepts: function accepts(el, target, source, sibling) {
            // accept drags only if the drop target is the same
            // as the source container the element came from
            return target == source;
          },
          moves: function moves(el, container, handle) {
            return handle.classList.contains('handle') || handle.classList.contains('handle-svg');
          }
        });
        drake.on('drop', function (el, container, source, sibling) {
          var orders = [];
          var _iteratorNormalCompletion2 = true;
          var _didIteratorError2 = false;
          var _iteratorError2 = undefined;

          try {
            for (var _iterator2 = container.childNodes[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
              var child = _step2.value;

              if (child.getAttribute && child.getAttribute("data-id")) {
                var id = child.getAttribute("data-id");
                orders.push(Number(id));
              }
            }
          } catch (err) {
            _didIteratorError2 = true;
            _iteratorError2 = err;
          } finally {
            try {
              if (!_iteratorNormalCompletion2 && _iterator2.return != null) {
                _iterator2.return();
              }
            } finally {
              if (_didIteratorError2) {
                throw _iteratorError2;
              }
            }
          }

          if (orders.length > 1) {
            var path = _environment.default.APP.API_HOST + '/custom-fields/orders';
            self.ajax.requestWithBodyPromise(path, 'PATCH', 'application/json', JSON.stringify(orders));
          }
        });
        Ember.set(_this4, 'drake', drake);
      }, 1000);
    },
    loadCustomFields: function loadCustomFields() {
      var _this5 = this;

      this.ajax.requestPromise(_environment.default.APP.API_HOST + '/custom-fields', 'GET').then(function (customFields) {
        Ember.set(_this5, "customFields", customFields);
        setTimeout(function () {
          _this5.initDrake();
        });
      });
    },
    actions: {
      openAddCustomModal: function openAddCustomModal() {
        Ember.set(this, "updateCustomField", undefined);
        Ember.set(this, "showAddCustomModal", true);
      },
      openActions: function openActions(id) {
        Ember.set(this, 'showActions', id);
      },
      editCustomField: function editCustomField(customField) {
        Ember.set(this, 'showActions', false);
        Ember.set(this, "updateCustomField", customField);
        Ember.set(this, "showAddCustomModal", true);
      },
      prepareDeleteCustom: function prepareDeleteCustom(customField) {
        Ember.set(this, 'showActions', false);
        Ember.set(this, 'customToDelete', customField);
      },
      removeCustom: function removeCustom(response) {
        var _this6 = this;

        if (response) {
          var customId = this.customToDelete.id;
          var path = _environment.default.APP.API_HOST + '/custom-fields/' + customId;
          this.ajax.requestPromise(path, 'DELETE').then(function () {
            _this6.loadCustomFields();
          });
        }
      },
      translateCustomField: function translateCustomField(customField) {
        Ember.set(this, "updateCustomField", customField);
        Ember.set(this, 'showTranslateCustomModal', true);
        Ember.set(this, 'showActions', false);
      },
      onFinish: function onFinish() {
        this.loadCustomFields();
      },
      open: function open(step) {
        Ember.set(this, 'step', step);
      },
      cfChanged: function cfChanged(cf) {
        var _this7 = this;

        if (cf.name && cf.name.length) {
          Ember.set(cf, 'required', !cf.required);
          var path = _environment.default.APP.API_HOST + '/custom-fields/' + cf.id;
          this.ajax.requestWithBodyPromise(path, 'PATCH', 'application/json', JSON.stringify(cf)).then(function () {
            _this7.notify.info(_this7.intl.t('settings.talent-data.updated', {
              cf: cf.name
            }), {
              closeAfter: 3000
            });
          });
        }
      },
      companyUserDefaultFieldChanged: function companyUserDefaultFieldChanged(field) {
        Ember.set(this.companyUserDefaultField, field, !this.companyUserDefaultField[field]);
        var path = _environment.default.APP.API_HOST + '/companies/required-user-fields';
        this.ajax.requestWithBodyPromise(path, 'PATCH', 'application/json', JSON.stringify(this.companyUserDefaultField)).then(function () {});
      },
      toggleReferent: function toggleReferent(consultRole) {
        Ember.set(consultRole, 'required', !consultRole.required);
        var path = _environment.default.APP.API_HOST + "/roles/" + consultRole.id;
        this.ajax.requestWithBodyPromise(path, "PATCH", 'application/json', JSON.stringify(consultRole)).then(function () {});
      }
    }
  });

  _exports.default = _default;
});