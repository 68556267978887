define("teelt-frontend/components/talents/talent-profile-options", ["exports", "teelt-frontend/config/environment", "teelt-frontend/utils/jquery-utils", "jquery"], function (_exports, _environment, _jqueryUtils, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['talent-profile-options'],
    defaultProfileImageUrl: _environment.default.APP.DEFAULT_PROFILE_IMAGE_URL,
    systemStateLink: "https://teelt.statuspage.io/",
    helpCenterLink: "https://teelt.notion.site/Base-de-connaissance-31fc93106d2e450596ae496fe8ba9704",
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    intl: Ember.inject.service(),
    userService: Ember.inject.service(),
    eventBus: Ember.inject.service('event-bus'),
    router: Ember.inject.service(),
    rolecustomService: Ember.inject.service('rolecustom-service'),
    googleCalendar: Ember.inject.service('google-calendar'),
    notify: Ember.inject.service(),
    canSwitchRole: false,
    getGoogleLoginLink: _environment.default.APP.API_HOST + "/googleapis/loginlink",
    getAzureLoginLink: _environment.default.APP.API_HOST + "/azureapis/loginlink",
    init: function init() {
      this._super.apply(this, arguments);

      this.eventBus.on('ROLELIST_UPDATED', this, 'loadRoles');
      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = this.session.data.authenticated.user.roles[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var role = _step.value;

          if (role != "ROLE_USER") {
            Ember.set(this, 'canSwitchRole', true);
            break;
          }
        }
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return != null) {
            _iterator.return();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }

      var currentRole = localStorage.getItem('currentRole');
      Ember.set(this, 'currentRole', currentRole);
      this.loadRoles();
      this.checkExternalCalendars();
    },
    checkExternalCalendars: function checkExternalCalendars() {
      var _this = this;

      if (this.user.company.useGoogleCalendar) {
        this.googleCalendar.isConnected().then(function (connected) {
          Ember.set(_this, "isConnectedGoogle", connected);
        }).catch(function () {});
      }

      if (this.user.company.useAzureCalendar) {
        var url = _environment.default.APP.API_HOST + "/azureapis/calendar/connected";
        return this.ajax.requestPromise(url, "GET").then(function (connected) {
          Ember.set(_this, "isConnectedAzure", connected);
        }).catch(function () {});
      }
    },
    loadRoles: function loadRoles() {
      var _this2 = this;

      var path = _environment.default.APP.API_HOST + "/users/me/roles";
      this.ajax.requestPromise(path).then(function (roles) {
        var userRoles = roles.filter(function (r) {
          return r != 'ROLE_CONSULT' && r != 'ROLE_USER';
        });
        userRoles = ["ROLE_ALL"].concat(userRoles).concat(['ROLE_USER']);
        var selectRoles = userRoles.map(function (role) {
          if (role == 'ROLE_ALL') {
            var label = _this2.intl.t('talent.roles.all');

            return {
              key: role,
              label: label
            };
          } else {
            var _label = _this2.rolecustomService.getRoleName(role);

            return {
              key: role,
              label: _label
            };
          }
        });
        Ember.set(_this2, 'accreditations', selectRoles);
      });
    },
    destroy: function destroy() {
      this.eventBus.off('CLICK_EVENT', this, 'checkToCloseModal');
      this.eventBus.off('ROLELIST_UPDATED', this, 'loadRoles');

      this._super.apply(this, arguments);
    },
    checkToCloseModal: function checkToCloseModal(e) {
      if (!(0, _jqueryUtils.default)().checkIfClickInElement(e, ["talent-profile-options-modal", "switch-role"])) {
        Ember.set(this, 'showProfileOptions', false);
      }
    },
    didInsertElement: function didInsertElement() {
      var _this3 = this;

      this._super.apply(this, arguments);

      (0, _jquery.default)('.talent-profile-options').css({
        'top': (0, _jquery.default)('#profileImageLink').position().top - (0, _jquery.default)('.talent-profile-options').height() + 60
      });
      setTimeout(function () {
        _this3.eventBus.on('CLICK_EVENT', _this3, 'checkToCloseModal');
      }, 300);
    },
    switchRole: function switchRole() {
      var _this4 = this;

      if (this.currentRole == 'ROLE_ALL') {
        this.session.authenticate('authenticator:impersonateRestore').then(function () {
          return _this4.treatRole();
        });
        localStorage.removeItem('isImpersonateRole');
      } else {
        var isImpersonateRole = localStorage.getItem('isImpersonateRole');

        if (isImpersonateRole) {
          localStorage.removeItem('isImpersonateRole');
          this.session.authenticate('authenticator:impersonateRestore').then(function () {
            _this4.session.authenticate('authenticator:impersonate', _this4.user.id, _this4.currentRole).then(function () {
              return _this4.treatRole();
            });
          }).catch(function () {
            _this4.session.authenticate('authenticator:impersonate', _this4.user.id, _this4.currentRole).then(function () {
              return _this4.treatRole();
            });
          });
        } else {
          this.session.authenticate('authenticator:impersonate', this.user.id, this.currentRole).then(function () {
            return _this4.treatRole();
          });
        }
      }
    },
    treatRole: function treatRole() {
      if (this.userService.hasAnyRole(this.session.data.authenticated.user, 'ROLE_ADMIN')) {
        this.router.transitionTo("board.admin.company");
      } else if (this.userService.hasAnyRole(this.session.data.authenticated.user, 'ROLE_RH', 'ROLE_MANAGER', 'ROLE_CUSTOM_OFFICE_MANAGER', 'ROLE_ADMINISTRATIVE', 'ROLE_CONSULT')) {
        this.router.transitionTo("board.talents");
      } else {
        this.router.transitionTo("talent.home");
      }

      localStorage.setItem('isImpersonateRole', true);
      localStorage.setItem('currentRole', this.currentRole);

      if (this.currentRole == 'ROLE_USER') {
        localStorage.setItem('restoreImpersonate', true);
        this.eventBus.trigger('VERIFY_RESTORE_IMPERSONATE');
      }

      setTimeout(function () {
        document.location.reload();
      }, 200);
    },
    actions: {
      openProfile: function openProfile() {
        Ember.set(this, 'showProfileOptions', false);
        this.router.transitionTo('board.myaccount');
      },
      onRoleChanged: function onRoleChanged(value) {
        Ember.set(this, 'currentRole', value.key);
        Ember.set(this, 'showSwitchRoles', false);
        this.switchRole();
      },
      logout: function logout() {
        var _this5 = this;

        this.session.invalidate().then(function () {
          _this5.router.transitionTo('login.form');
        });
      },
      connectGoogleCalendar: function connectGoogleCalendar() {
        this.ajax.requestWithBodyPromise(this.getGoogleLoginLink, 'POST', "application/json", JSON.stringify({
          redirect: window.location.href
        })).then(function (authLink) {
          var link = authLink.link.replace(/%25STATE%25/g, authLink.token + "_" + false);
          window.location.replace(link);
        });
      },
      disconnectGoogleCalendar: function disconnectGoogleCalendar() {
        var _this6 = this;

        this.ajax.requestWithBodyPromise(this.getGoogleLoginLink, 'DELETE').then(function () {
          Ember.set(_this6, "isConnectedGoogle", false);
        });
      },
      connectAzureApis: function connectAzureApis() {
        this.ajax.requestWithBodyPromise(this.getAzureLoginLink, 'POST', "application/json", JSON.stringify({
          redirect: window.location.href,
          shared: false
        })).then(function (res) {
          window.location.replace(res.link);
        });
      },
      disconnectAzureApis: function disconnectAzureApis() {
        var _this7 = this;

        this.ajax.requestWithBodyPromise(this.getAzureLoginLink, 'DELETE', "application/json", JSON.stringify({
          redirect: window.location.href
        })).then(function (res) {
          Ember.set(_this7, "isConnectedAzure", false);
        });
      },
      forceRefreshGoogleCalendar: function forceRefreshGoogleCalendar() {
        var _this8 = this;

        var path = _environment.default.APP.API_HOST + "/googleapis/calendar";
        this.ajax.requestPromise(path, 'PATCH').then(function () {
          _this8.notify.info(_this8.intl.t('talent_profile_options.agenda_sync'));
        });
      },
      forceRefreshAzureCalendar: function forceRefreshAzureCalendar() {
        var _this9 = this;

        var path = _environment.default.APP.API_HOST + "/azureapis/calendar";
        this.ajax.requestPromise(path, 'PATCH').then(function () {
          _this9.notify.info(_this9.intl.t('talent_profile_options.agenda_sync'));
        });
      }
    }
  });

  _exports.default = _default;
});