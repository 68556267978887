define("teelt-frontend/components/utils/searchable-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['searchable-select'],
    intl: Ember.inject.service(),
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    customErrorService: Ember.inject.service('custom-error'),
    userService: Ember.inject.service(),
    store: Ember.inject.service(),
    notify: Ember.inject.service(),
    limit: 10,
    search: '',
    init: function init() {
      this._super.apply(this, arguments);

      this.filterValues();
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      if (this.defaultValue) {
        this.$('.select-dropdown').val(this.defaultValue);
      }
    },
    filterValues: function filterValues() {
      var _this = this;

      if (this.values) {
        if (!this.multiple) {
          if (this.search.length == 0) {
            Ember.set(this, 'filteredValues', this.values.slice(0, this.limit));
          } else {
            Ember.set(this, 'filteredValues', this.values.filter(function (value) {
              return value.label.toLowerCase().indexOf(_this.search.toLowerCase()) >= 0;
            }));
          }
        } else {
          var i = 0;
          Ember.set(this, 'filteredValues', this.values.map(function (value) {
            Ember.set(value, 'selected', _this.isSelected(value));
            return value;
          }).filter(function (value) {
            return i++ < _this.limit && _this.search.length == 0 || value.label.toLowerCase().indexOf(_this.search.toLowerCase()) >= 0 && _this.search.length > 0 || value.selected;
          }));
        }
      } else if (this.searchPath) {
        if (this.searchCall) {
          clearTimeout(this.searchCall);
        }

        var searchCall = setTimeout(function () {
          var path = _this.searchPath.indexOf("?") > 0 ? _this.searchPath + "&limit=" + _this.limit + "&search=" + _this.search : _this.searchPath + "?limit=" + _this.limit + "&search=" + _this.search;

          _this.ajax.requestPromise(path).then(function (results) {
            var filteredValues = [{
              key: 0,
              label: ''
            }];

            if (_this.additionalValues) {
              var _iteratorNormalCompletion = true;
              var _didIteratorError = false;
              var _iteratorError = undefined;

              try {
                for (var _iterator = _this.additionalValues[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
                  var additionalValue = _step.value;

                  if (additionalValue.label.toLowerCase().indexOf(_this.search.toLowerCase()) >= 0) {
                    filteredValues.push(additionalValue);
                  }
                }
              } catch (err) {
                _didIteratorError = true;
                _iteratorError = err;
              } finally {
                try {
                  if (!_iteratorNormalCompletion && _iterator.return != null) {
                    _iterator.return();
                  }
                } finally {
                  if (_didIteratorError) {
                    throw _iteratorError;
                  }
                }
              }
            }

            filteredValues = filteredValues.concat(results.map(function (result) {
              return {
                key: result[_this.keyAtt],
                label: _this.getLabelForResult ? _this.getLabelForResult(result) : result[_this.keyValue]
              };
            }));
            Ember.set(_this, 'filteredValues', filteredValues);

            if (_this.selected) {
              _this.selected.forEach(function (selectedObj) {
                _this.filteredValues.forEach(function (filteredObj) {
                  if (selectedObj.key == filteredObj.key) {
                    Ember.set(filteredObj, 'selected', _this.isSelected(filteredObj));
                  }
                });
              });

              _this.$('.select-dropdown').val(_this.intl.t("word.selected_nb", {
                nb: _this.selected.length
              }));
            }
          });
        }, 200);
        Ember.set(this, 'searchCall', searchCall);
      }
    },
    isSelected: function isSelected(value) {
      if (!this.selected) return false;
      var _iteratorNormalCompletion2 = true;
      var _didIteratorError2 = false;
      var _iteratorError2 = undefined;

      try {
        for (var _iterator2 = this.selected[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
          var sel = _step2.value;
          if (sel.key == value.key) return true;
        }
      } catch (err) {
        _didIteratorError2 = true;
        _iteratorError2 = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion2 && _iterator2.return != null) {
            _iterator2.return();
          }
        } finally {
          if (_didIteratorError2) {
            throw _iteratorError2;
          }
        }
      }

      return false;
    },
    actions: {
      onSearchChange: function onSearchChange() {
        this.filterValues();
      },
      select: function select(value) {
        if (!this.multiple) {
          Ember.set(this, 'selected', value);
          Ember.set(this, 'showList', false);

          if (this.onValueChange) {
            this.onValueChange(value);
          }

          if (value) {
            this.$('.select-dropdown').val(value.label);
          } else {
            this.$('.select-dropdown').val("");
          }
        } else {
          var selected = this.selected;

          if (!selected) {
            selected = [];
          }

          var found = false;

          for (var i = 0; i < selected.length; i++) {
            if (selected[i].key == value.key) {
              selected.splice(i, 1);
              found = true;
              break;
            }
          }

          if (!found) {
            selected.push(value);
          }

          Ember.set(this, 'selected', selected);
          this.filterValues();
          this.$('.select-dropdown').val(this.intl.t("word.selected_nb", {
            nb: this.selected.length
          }));

          if (this.onValueChange) {
            this.onValueChange(selected);
          }
        }
      },
      onAddAction: function onAddAction() {
        if (this.canAddAction) {
          this.canAddAction();
        }
      }
    }
  });

  _exports.default = _default;
});