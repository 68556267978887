define("teelt-frontend/routes/board/setting/design", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    fileUploadService: Ember.inject.service('file-upload'),
    session: Ember.inject.service(),
    router: Ember.inject.service(),
    onMediaLogoProgress: function onMediaLogoProgress(e) {
      if (e.lengthComputable) {
        var progress = Math.ceil(e.loaded / e.total * 100);
        Ember.set(this.currentModel, 'logoProgress', progress);
      }
    },
    onMediaLogoBackProgress: function onMediaLogoBackProgress(e) {
      if (e.lengthComputable) {
        var progress = Math.ceil(e.loaded / e.total * 100);
        Ember.set(this.currentModel, 'logoBackProgress', progress);
      }
    },
    onMediaBackgroundProgress: function onMediaBackgroundProgress(e) {
      if (e.lengthComputable) {
        var progress = Math.ceil(e.loaded / e.total * 100);
        Ember.set(this.currentModel, 'backgroundProgress', progress);
      }
    },
    onMediaBackgroundVideoProgress: function onMediaBackgroundVideoProgress(e) {
      if (e.lengthComputable) {
        var progress = Math.ceil(e.loaded / e.total * 100);
        Ember.set(this.currentModel, 'backgroundVideoProgress', progress);
      }
    },
    replaceCompanyAuthenticated: function replaceCompanyAuthenticated() {
      Ember.set(this.session.data.authenticated.user, 'company', this.currentModel.company);
    },
    actions: {
      onMediaLogoChanged: function onMediaLogoChanged(file) {
        var _this = this;

        this.fileUploadService.signAndUpload(file, false, function (e) {
          return _this.onMediaLogoProgress(e);
        }).then(function (fileUrl) {
          Ember.set(_this.currentModel.company, "logoUrl", fileUrl);

          _this.currentModel.company.save().then(function () {
            return _this.replaceCompanyAuthenticated();
          });
        });
      },
      onMediaLogoBackChanged: function onMediaLogoBackChanged(file) {
        var _this2 = this;

        this.fileUploadService.signAndUpload(file, false, function (e) {
          return _this2.onMediaLogoBackProgress(e);
        }).then(function (fileUrl) {
          Ember.set(_this2.currentModel.company, "logoOnBackgroundColorUrl", fileUrl);

          _this2.currentModel.company.save().then(function () {
            return _this2.replaceCompanyAuthenticated();
          });
        });
      },
      onMediaBackgroundChanged: function onMediaBackgroundChanged(file) {
        var _this3 = this;

        this.fileUploadService.signAndUpload(file, false, function (e) {
          return _this3.onMediaBackgroundProgress(e);
        }).then(function (fileUrl) {
          Ember.set(_this3.currentModel.company, "backgroundUrl", fileUrl);

          _this3.currentModel.company.save().then(function () {
            return _this3.replaceCompanyAuthenticated();
          });
        });
      },
      onMediaBackgroundVideoChanged: function onMediaBackgroundVideoChanged(file) {
        var _this4 = this;

        this.fileUploadService.signAndUpload(file, false, function (e) {
          return _this4.onMediaBackgroundVideoProgress(e);
        }).then(function (fileUrl) {
          Ember.set(_this4.currentModel.company, "backgroundVideoUrl", fileUrl);

          _this4.currentModel.company.save().then(function () {
            return _this4.replaceCompanyAuthenticated();
          });
        });
      },
      changeBackColor: function changeBackColor(color) {
        var _this5 = this;

        Ember.set(this.currentModel.company, "backgroundColor", color);
        this.currentModel.company.save().then(function () {
          return _this5.replaceCompanyAuthenticated();
        });
      },
      changeForeColor: function changeForeColor(color) {
        var _this6 = this;

        Ember.set(this.currentModel.company, "foregroundColor", color);
        this.currentModel.company.save().then(function () {
          return _this6.replaceCompanyAuthenticated();
        });
      },
      openDefaultHomePage: function openDefaultHomePage() {
        this.router.transitionTo('board.setting.homepage-default');
      },
      handleFocusOut: function handleFocusOut() {
        var _this7 = this;

        this.currentModel.company.save().then(function () {
          return _this7.replaceCompanyAuthenticated();
        });
      },
      interceptEnter: function interceptEnter(e1, e2) {
        if (e2.keyCode == 13) {
          this.send('handleFocusOut', e2);
          return false;
        }

        return true;
      }
    }
  });

  _exports.default = _default;
});