define("teelt-frontend/components/form/field/field-choice-builder", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['field-choice-builder'],
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      if (!this.field.possibleResponses) {
        Ember.set(this.field, 'possibleResponses', [{}]);
      } else {
        var newFields = this.field.possibleResponses;
        Ember.set(this.field, 'possibleResponses', []);
        newFields.sort(function (a, b) {
          return a.order - b.order;
        }); //not working without timeout

        setTimeout(function () {
          Ember.set(_this.field, 'possibleResponses', newFields);
        }, 100);
      }
    },
    actions: {
      onDeleteItem: function onDeleteItem(item) {
        this.field.possibleResponses.removeObject(item);
      },
      addChoice: function addChoice() {
        this.field.possibleResponses.pushObject({
          label: ''
        });
      },
      onChangeValid: function onChangeValid(index) {
        if (this.formType !== "QUIZZ" || this.field.properties.allow_multiple_selection) return;
        this.field.possibleResponses.forEach(function (choice, i) {
          if (i !== index) {
            Ember.set(choice, 'isValid', false);
          }
        });
      },
      onChangeMultipleSelection: function onChangeMultipleSelection() {
        Ember.set(this.field.properties, 'allow_multiple_selection', !this.field.properties.allow_multiple_selection);

        if (!this.field.properties.allow_multiple_selection) {
          this.send('onChangeValid', -1);
        }
      },
      onChangeQuantifySelection: function onChangeQuantifySelection() {
        Ember.set(this.field.properties, 'multiple_choice_quantify', !this.field.properties.multiple_choice_quantify);

        if (!this.field.properties.multiple_choice_quantify) {
          this.send('onChangeValid', -1);
        }
      }
    }
  });

  _exports.default = _default;
});