define("teelt-frontend/components/activity/activity-list", ["exports", "teelt-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['activity-list'],
    ajax: Ember.inject.service(),
    router: Ember.inject.service(),
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      var path = _environment.default.APP.API_HOST + "/activities/users/" + this.talent.id;
      this.ajax.requestPromise(path).then(function (groups) {
        Ember.set(_this, 'groups', groups);
      }).catch(function () {
        _this.router.transitionTo('board.talent.talent-detail.actions', _this.talent.id);
      });
      var path2 = _environment.default.APP.API_HOST + "/activities/users/" + this.talent.id + "/scheduled-notifications";
      this.ajax.requestPromise(path2).then(function (notificationGroups) {
        Ember.set(_this, 'notificationGroups', notificationGroups);
      }).catch(function () {
        _this.router.transitionTo('board.talent.talent-detail.actions', _this.talent.id);
      });
    }
  });

  _exports.default = _default;
});