define("teelt-frontend/components/task/new-task/new-task-content-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    selectedContent: undefined,
    init: function init() {
      this._super.apply(this, arguments);
    },
    actions: {
      onSelectContent: function onSelectContent(content) {
        if (content && content.selected) {
          if (this.selectedContent) {
            Ember.set(this.selectedContent, 'selected', undefined);
          }

          Ember.set(this, 'selectedContent', content);
        } else {
          if (this.selectedContent) {
            Ember.set(this.selectedContent, 'selected', undefined);
          }

          Ember.set(this, 'selectedContent', undefined);
        }

        if (this.onContentSelected) {
          this.onContentSelected(content);
        }
      }
    }
  });

  _exports.default = _default;
});