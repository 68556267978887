define("teelt-frontend/routes/login/recover-password", ["exports", "teelt-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _EmberRoute$extend;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = Ember.Route.extend((_EmberRoute$extend = {
    queryParams: {
      token: '',
      invite: ''
    },
    intl: Ember.inject.service(),
    userService: Ember.inject.service(),
    session: Ember.inject.service()
  }, _defineProperty(_EmberRoute$extend, "userService", Ember.inject.service('user-service')), _defineProperty(_EmberRoute$extend, "ajax", Ember.inject.service()), _defineProperty(_EmberRoute$extend, "getBackground", Ember.inject.service('get-background')), _defineProperty(_EmberRoute$extend, "customErrorService", Ember.inject.service('custom-error')), _defineProperty(_EmberRoute$extend, "model", function model(params) {
    return {
      token: params.token,
      invite: params.invite
    };
  }), _defineProperty(_EmberRoute$extend, "afterModel", function afterModel(model) {
    var _this = this;

    var subdomain = window.location.host.split('.')[1] ? window.location.host.split('.')[0] : 'default';
    var path = _environment.default.APP.API_HOST + "/companies/customizations/domain?domain=" + subdomain;
    this.ajax.requestPromise(path, 'GET').then(function (customizations) {
      _this.getBackground.getBackground(model, customizations);
    });
  }), _defineProperty(_EmberRoute$extend, "eyeDisplay", function eyeDisplay(eyeId, inputId, value) {
    var eye = document.getElementById(eyeId);

    if (value != '') {
      eye.style.visibility = "visible";
    } else {
      eye.style.visibility = "hidden";
      var input = document.getElementById(inputId);
      input.type = "password";
      eye.textContent = "visibility";
    }
  }), _defineProperty(_EmberRoute$extend, "actions", {
    onPasswordInput: function onPasswordInput(value) {
      this.eyeDisplay('password-eye', 'password', value);
      var rulesItems = document.querySelectorAll('.fieldRules li');
      var rules = {
        "one_lowercase": /[a-z]/,
        "one_uppercase": /[A-Z]/,
        "one_number": /[0-9]/,
        "one_special_character": /[^a-z0-9\/\\:;=]/i,
        "min_size": /.{9,}/
      };
      rulesItems.forEach(function (elm) {
        var valid,
            ruleName = elm.id;

        if (rules[ruleName]) {
          valid = new RegExp(rules[ruleName]).test(value);
          elm.classList.toggle('pass', valid);
        }
      });
    },
    onConfirmPasswordInput: function onConfirmPasswordInput(value) {
      this.eyeDisplay('confirm-password-eye', 'passwordConfirm', value);
    },
    updatePassword: function updatePassword() {
      var _this2 = this;

      if (this.currentModel.password && this.currentModel.passwordConfirm) {
        if (this.currentModel.password.length < 9) {
          Ember.set(this.currentModel, "error", this.intl.t('recover_password.errors.min_size_8'));
          return;
        }

        var specialCharacterFormat = /[!@#$%^&*()_+\-\[\]{}'"|,.<>?]+/;
        var specialCharatersDisallowed = /[\/\\:;=]/;

        if (specialCharatersDisallowed.test(this.currentModel.password)) {
          Ember.set(this.currentModel, "error", this.intl.t('recover_password.rules.special_characters_disallowed'));
          return;
        }

        if (!this.currentModel.password.match(/[A-Z]/, 'g') || !this.currentModel.password.match(/[a-z]/, 'g') || !this.currentModel.password.match(/[0-9]/, 'g') || !specialCharacterFormat.test(this.currentModel.password)) {
          Ember.set(this.currentModel, "error", this.intl.t('recover_password.errors.not_secure_password'));
          return;
        }

        if (this.currentModel.password != this.currentModel.passwordConfirm) {
          Ember.set(this.currentModel, "error", this.intl.t('recover_password.errors.not_same_password'));
          return;
        }

        this.get('userService').updatePasswordFromToken(this.currentModel.token, this.currentModel.password).then(function (user) {
          Ember.set(_this2.currentModel, "confirmMessage", _this2.intl.t('recover_password.messages.password_updated'));
          Ember.run.later(function () {
            _this2.get('session').authenticate('authenticator:basic', user.username, _this2.currentModel.password).then(function () {
              if (_this2.userService.hasAnyRole(_this2.session.data.authenticated.user, 'ROLE_RH', 'ROLE_ADMIN', 'ROLE_MANAGER', 'ROLE_CUSTOM_OFFICE_MANAGER', 'ROLE_ADMINISTRATIVE')) {
                if (_this2.currentModel.invite == 'true' && _this2.session.data.authenticated.user.templateId) {
                  _this2.transitionTo("talent.home");
                } else {
                  _this2.transitionTo("board.talents");
                }
              } else {
                _this2.transitionTo("talent.home");
              }

              setTimeout(function () {
                var currentUrl = Ember.getOwner(_this2).lookup('router:main').get('currentURL');

                if (currentUrl == "/login/form" || currentUrl == "/login/recover-password") {
                  _this2.customErrorService.errorWithIcon(_this2.intl.t('login.errors.mutliple_open_windows'), 10000);
                }
              }, 1000);
            }, function (err) {
              Ember.set(_this2.currentModel, "error", _this2.intl.t('recover_password.errors.default'));
            });
          }, 5000);
        }).catch(function (err) {
          Ember.set(_this2.currentModel, "error", _this2.intl.t('recover_password.errors.default'));
        });
      }
    },
    togglePasswordVisibility: function togglePasswordVisibility(isConfirm) {
      var eye = document.getElementById(isConfirm ? 'confirm-password-eye' : 'password-eye');
      var input = document.getElementById(isConfirm ? 'passwordConfirm' : 'password');

      if (eye.textContent === "visibility") {
        eye.textContent += "_off";
        input.type = "text";
      } else {
        eye.textContent = eye.textContent.replace("_off", "");
        input.type = "password";
      }
    }
  }), _EmberRoute$extend));

  _exports.default = _default;
});