define("teelt-frontend/services/rolecustom-service", ["exports", "teelt-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    ajax: Ember.inject.service(),
    eventBus: Ember.inject.service(),
    session: Ember.inject.service(),
    intl: Ember.inject.service(),
    translation: Ember.inject.service(),
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      this.reloadCustomRoles();
      this.eventBus.on('REFRESH_ROLES', this, 'reloadCustomRoles');
      this.eventBus.on('AUTHENTICATION_CHANGED', this, 'reloadCustomRoles');
      var interval = setInterval(function () {
        _this.reloadCustomRoles();
      }, 15 * 60 * 1000);
      Ember.set(this, 'interval', interval);
    },
    destroy: function destroy() {
      this.eventBus.off('REFRESH_ROLES', this, 'reloadCustomRoles');
      this.eventBus.off('AUTHENTICATION_CHANGED', this, 'reloadCustomRoles');

      if (this.interval) {
        clearInterval(this.interval);
      }

      this._super.apply(this, arguments);
    },
    reloadCustomRoles: function reloadCustomRoles() {
      var _this2 = this;

      if (this.session.isAuthenticated) {
        var path = _environment.default.APP.API_HOST + '/roles';
        var promises = [];
        promises.push(this.ajax.requestPromise(path).then(function (roles) {
          Ember.set(_this2, 'roles', roles);
          var translationPromises = [];
          var _iteratorNormalCompletion = true;
          var _didIteratorError = false;
          var _iteratorError = undefined;

          try {
            for (var _iterator = roles[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
              var role = _step.value;

              if (role.id > 0) {
                translationPromises.push(_this2.translation.loadTranslations('role_' + role.id));
              }
            }
          } catch (err) {
            _didIteratorError = true;
            _iteratorError = err;
          } finally {
            try {
              if (!_iteratorNormalCompletion && _iterator.return != null) {
                _iterator.return();
              }
            } finally {
              if (_didIteratorError) {
                throw _iteratorError;
              }
            }
          }

          Promise.all(translationPromises).then(function () {
            _this2.eventBus.trigger('ROLELIST_UPDATED');
          });
        }));
        var path2 = _environment.default.APP.API_HOST + '/roles?consultRoles=true';
        promises.push(this.ajax.requestPromise(path2).then(function (roles) {
          Ember.set(_this2, 'consultRoles', roles);
          var translationPromises = [];
          var _iteratorNormalCompletion2 = true;
          var _didIteratorError2 = false;
          var _iteratorError2 = undefined;

          try {
            for (var _iterator2 = roles[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
              var role = _step2.value;

              if (role.id > 0) {
                translationPromises.push(_this2.translation.loadTranslations('role_' + role.id));
              }
            }
          } catch (err) {
            _didIteratorError2 = true;
            _iteratorError2 = err;
          } finally {
            try {
              if (!_iteratorNormalCompletion2 && _iterator2.return != null) {
                _iterator2.return();
              }
            } finally {
              if (_didIteratorError2) {
                throw _iteratorError2;
              }
            }
          }

          Promise.all(translationPromises).then(function () {
            _this2.eventBus.trigger('ROLELIST_UPDATED');
          });
        }));
      }
    },
    translateRoleIfNecessary: function translateRoleIfNecessary(role) {
      switch (role) {
        case 'rh':
          role = 'ROLE_RH';
          break;

        case 'adminMRH':
        case 'admin_mrh':
          role = 'ROLE_MRH';
          break;

        case 'officeManager':
        case 'om':
          role = 'ROLE_CUSTOM_OFFICE_MANAGER';
          break;

        case 'manager':
          role = 'ROLE_MANAGER';
          break;

        case 'buddy':
        case 'sponsor':
          role = 'ROLE_BUDDY';
          break;

        case 'administrative':
          role = 'ROLE_ADMINISTRATIVE';
          break;
      }

      return role ? role.toUpperCase() : undefined;
    },
    getCustomRole: function getCustomRole(role) {
      if (!role) return;
      role = this.translateRoleIfNecessary(role);

      if (role.toLowerCase().indexOf('role_consult') == 0) {
        if (this.consultRoles) {
          var id = role.split('-')[1];
          var _iteratorNormalCompletion3 = true;
          var _didIteratorError3 = false;
          var _iteratorError3 = undefined;

          try {
            for (var _iterator3 = this.consultRoles[Symbol.iterator](), _step3; !(_iteratorNormalCompletion3 = (_step3 = _iterator3.next()).done); _iteratorNormalCompletion3 = true) {
              var customRole = _step3.value;

              if (customRole.id == id) {
                return customRole;
              }
            }
          } catch (err) {
            _didIteratorError3 = true;
            _iteratorError3 = err;
          } finally {
            try {
              if (!_iteratorNormalCompletion3 && _iterator3.return != null) {
                _iterator3.return();
              }
            } finally {
              if (_didIteratorError3) {
                throw _iteratorError3;
              }
            }
          }
        }
      } else if (this.roles) {
        var _iteratorNormalCompletion4 = true;
        var _didIteratorError4 = false;
        var _iteratorError4 = undefined;

        try {
          for (var _iterator4 = this.roles[Symbol.iterator](), _step4; !(_iteratorNormalCompletion4 = (_step4 = _iterator4.next()).done); _iteratorNormalCompletion4 = true) {
            var _customRole = _step4.value;

            if (_customRole.roleType == role) {
              return _customRole;
            }
          }
        } catch (err) {
          _didIteratorError4 = true;
          _iteratorError4 = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion4 && _iterator4.return != null) {
              _iterator4.return();
            }
          } finally {
            if (_didIteratorError4) {
              throw _iteratorError4;
            }
          }
        }
      }
    },
    getRoleName: function getRoleName(role) {
      var lang = 'en';

      if (this.session.isAuthenticated && this.session.data.authenticated.user.lang) {
        lang = this.session.data.authenticated.user.lang;
      }

      role = this.translateRoleIfNecessary(role);
      var customRole = this.getCustomRole(role);

      if (customRole) {
        var roleTranslations = this.translation.getTranslations('role_' + customRole.id);

        if (roleTranslations) {
          if (roleTranslations[lang]) return roleTranslations[lang];
          if (roleTranslations['en']) return roleTranslations['en'];

          if (customRole.label) {
            return customRole.label;
          }
        }
      }

      if (this.intl.exists('settings.roles.default.' + role)) {
        return this.intl.t('settings.roles.default.' + role);
      }

      return '';
    },
    getRoles: function getRoles() {
      return this.roles;
    },
    getConsultRoles: function getConsultRoles() {
      return this.consultRoles;
    }
  });

  _exports.default = _default;
});