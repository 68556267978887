define("teelt-frontend/components/dashboard/user-info-content", ["exports", "teelt-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['user-info-content'],
    ajax: Ember.inject.service(),
    params: {
      onboardingType: 'welcome'
    },
    init: function init() {
      this._super.apply(this, arguments);

      this.loadData();
    },
    onTemplateTypeChanged: Ember.observer('templateType', function () {
      this.loadData();
    }),
    loadData: function loadData() {
      var _this = this;

      if (this.templateType) {
        var onboardingType = '';
        if (this.templateType == 'onboarding') onboardingType = 'welcome';
        if (this.templateType == 'crossboarding') onboardingType = 'migration';
        if (this.templateType == 'offboarding') onboardingType = 'end';
        Ember.set(this.params, 'onboardingType', onboardingType);
        this.ajax.requestWithUrlParamPromise(_environment.default.APP.API_HOST + '/users/info', 'GET', this.params).then(function (data) {
          Ember.set(_this, 'count', data.userCount);
          var completionRate = Math.round(data.completionRate);
          var connectionRate = Math.round(data.connectionRate);
          Ember.set(_this, 'completionRate', completionRate);
          Ember.set(_this, 'connectionRate', connectionRate);
        });
      }
    }
  });

  _exports.default = _default;
});