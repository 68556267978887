define("teelt-frontend/components/analytics/type/form-analytic", ["exports", "teelt-frontend/config/environment", "teelt-frontend/utils/jquery-utils"], function (_exports, _environment, _jqueryUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['form-analytic-detail'],
    store: Ember.inject.service(),
    intl: Ember.inject.service(),
    session: Ember.inject.service(),
    userService: Ember.inject.service('user-service'),
    ajax: Ember.inject.service(),
    eventBus: Ember.inject.service('event-bus'),
    notify: Ember.inject.service(),
    showRolesDropdown: false,
    query: '',
    prefixTranslateKey: "talent.table.keys.",
    params: {
      dateFilter: 'all',
      startDate: null,
      endDate: null,
      targetDateField: 'createdAt'
    },
    responseNb: 0,
    complexFilters: [],
    exportDataType: "defaultData",
    init: function init() {
      this._super.apply(this, arguments);
    },
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      this.loadForms();
      setTimeout(function () {
        _this.eventBus.on('CLICK_EVENT', _this, 'checkToCloseModal');
      }, 300);
    },
    destroy: function destroy() {
      this.eventBus.off('CLICK_EVENT', this, 'checkToCloseModal');

      this._super.apply(this, arguments);
    },
    checkToCloseModal: function checkToCloseModal(e) {
      if (this.isDestroyed) return;

      if (!(0, _jqueryUtils.default)().checkIfClickInElement(e, ["show-roles-btn", "roles"])) {
        Ember.set(this, 'showRolesDropdown', false);
      }
    },
    loadForms: function loadForms(data) {
      var _this2 = this;

      Ember.set(this, 'showChart', false);

      if (data) {
        Ember.set(this, 'params', {
          dateFilter: 'all',
          startDate: null,
          endDate: null,
          targetDateField: 'createdAt'
        });
        this.params.dateFilter = data.dateFilter;
        this.params.startDate = data.startDate;
        this.params.endDate = data.endDate;
        this.params.targetDateField = data.targetDateField;
      }

      this.params.complexFilters = JSON.stringify(this.complexFilters);
      this.params.include = 'full';
      Ember.set(this, 'isLoading', true);
      this.ajax.requestWithUrlParamPromise(_environment.default.APP.API_HOST + '/form-reports/' + this.formReportId, 'GET', this.params).then(function (formReport) {
        _this2.updateCustomFilters(JSON.parse(formReport.complexFilters));

        Ember.set(_this2, 'responseCount', formReport.responseCount);
        Ember.set(_this2, 'completionRate', formReport.completionRate);
        Ember.set(_this2, 'responseCountTotal', formReport.responseCountTotal);
        var values = [];
        var labels = [];
        var orderedMonths = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

        for (var _i = 0, _orderedMonths = orderedMonths; _i < _orderedMonths.length; _i++) {
          var month = _orderedMonths[_i];

          if (month in formReport.responseNbByMonth) {
            labels.push(month);
            values.push(formReport.responseNbByMonth[month]);
          }
        }

        Ember.set(formReport, 'responseNbByMonth', {
          labels: labels,
          values: values
        });
        formReport.formFields.forEach(function (formField) {
          if (formField.type == 'multiple_choice' || formField.type == 'opinion_scale') {
            var _data = {};
            formField.userFormFields.forEach(function (userFormField) {
              var value = userFormField.value;

              if (value) {
                value = value.replaceAll("\"", "");

                if (_data[value]) {
                  _data[value] = _data[value] + 1;
                } else {
                  _data[value] = 1;
                }
              }
            });
            var _values = [];
            var _labels = [];

            for (var item in _data) {
              _labels.push(item);

              _values.push(_data[item]);
            }

            if (formField.type == 'multiple_choice') {
              var responses = [];
              formField.possibleResponses.forEach(function (pr) {
                var count = 0;
                formField.userFormFields.forEach(function (userFormField) {
                  if (userFormField.value && userFormField.value.includes(pr.value)) {
                    count++;
                  }
                });
                responses.push({
                  label: pr.value,
                  value: count
                });
              });
              responses.sort(function (a, b) {
                return a.value < b.value ? 1 : -1;
              }); //on cherche, parmi les réponses multiples, quelle est la part relative de chaque réponse.
              // donc pour chaque réponse, on divise le nombre de fois qu'elle a été choisie par le nombre total de réponses

              var _labels2 = responses.map(function (response) {
                return response.label;
              });

              var _values2 = responses.map(function (response) {
                return (response.value / formField.userFormFields.length * 100).toFixed(2);
              });

              Ember.set(formField, 'results', {
                labels: _labels2,
                values: _values2,
                count: formField.userFormFields.length
              });
            } else {
              var pieChartData = [];

              for (var i = 0; i < _labels.length; i++) {
                pieChartData.push({
                  label: _labels[i],
                  value: _values[i],
                  count: _values.reduce(function (acc, value) {
                    return acc + value;
                  }, 0)
                });
              }

              Ember.set(formField, 'pieChartData', pieChartData);
            }
          }
        });
        Ember.set(_this2, 'formReport', formReport);
        Ember.set(_this2, 'showChart', true);
        Ember.set(_this2, 'isLoading', false);
      }).catch(function (error) {
        Ember.set(_this2, 'isLoading', false);

        _this2.notify.error(_this2.intl.t('analytics.loading_error'), {
          closeAfter: 5000
        });
      });
    },
    exportCsv: function exportCsv() {
      var _this3 = this;

      Ember.set(this, 'isLoading', true);
      var pathOTP = _environment.default.APP.API_HOST + "/users/otp";
      this.ajax.requestPromise(pathOTP).then(function (otp) {
        Ember.set(_this3, 'isLoading', false);
        var withAllTalentData = _this3.exportDataType == "defaultData" ? false : true;
        var path = _environment.default.APP.API_HOST + '/forms/download/form/' + _this3.formReport.form.id + "?otp=" + otp + "&withAllTalentData=" + withAllTalentData; //creating an invisible element

        var element = document.createElement('a');
        element.setAttribute('href', path);
        element.setAttribute("target", "_blank");
        document.body.appendChild(element); //onClick property

        element.click();
        document.body.removeChild(element);
      }).catch(function (error) {
        Ember.set(_this3, 'isLoading', false);
      });
    },
    updateCustomFilters: function updateCustomFilters(data) {
      if (data) {
        var complexFilters = this.complexFilters;

        var _loop = function _loop(i) {
          if (!complexFilters.some(function (item) {
            return JSON.stringify(item) === JSON.stringify(data[i]);
          })) {
            complexFilters.push(data[i]);
          }
        };

        for (var i = 0; i < data.length; i++) {
          _loop(i);
        }

        Ember.set(this, 'complexFilters', []);
        Ember.set(this, 'complexFilters', complexFilters);
      }
    },
    printPdfReport: function printPdfReport(withTexts) {
      var elem = document.getElementById("print-form-report"); // hide div with have class "no-print"

      var noPrint = document.getElementsByClassName("no-print");

      for (var i = 0; i < noPrint.length; i++) {
        noPrint[i].style.display = "none";
      }

      var s6ToS12Elements = elem.querySelectorAll('.s6.print-s12');
      s6ToS12Elements.forEach(function (col) {
        col.classList.remove('s6');
        col.classList.add('s12');
      });
      var s4ToS6elements = elem.querySelectorAll('.s4.print-s6');
      s4ToS6elements.forEach(function (col) {
        col.classList.remove('s4');
        col.classList.add('s6');
      });
      var s4ToS12elements = elem.querySelectorAll('.s4.print-s12');
      s4ToS12elements.forEach(function (col) {
        col.classList.remove('s4');
        col.classList.add('s12');
      });
      elem.querySelectorAll('.response-element').forEach(function (responseContainer) {
        responseContainer.style = 'font-weight: lighter; font-size: 2.5em;';
      });

      if (!withTexts) {
        document.getElementById('report-texts-part').style.display = 'none';
      }

      var footer = this.intl.t('analytics.report.pdf.footer');
      var opt = {
        image: {
          type: 'jpeg',
          quality: 0.98
        },
        html2canvas: {
          width: '90%',
          scrollX: 0,
          scrollY: 0,
          windowWidth: '100%',
          scale: 2,
          onclone: function onclone(element) {
            var customCharts = element.querySelectorAll('.customchart.col.s6');
            customCharts.forEach(function (chart) {
              chart.classList.remove('s6');
              chart.classList.add('s12');
            });
          }
        },
        jsPDF: {
          orientation: 'portrait'
        },
        margin: [10, 10, 10, 10],
        pagebreak: {
          mode: 'avoid-all'
        },
        filename: this.formReport.title + '.pdf'
      };
      html2pdf().set(opt).from(elem).toPdf().get('pdf').then(function (pdf) {
        var totalPages = pdf.internal.getNumberOfPages();
        var w = 20;
        var h = 20;

        for (var _i2 = 1; _i2 <= totalPages; _i2++) {
          var x = pdf.internal.pageSize.getWidth() - w;
          var y = pdf.internal.pageSize.getHeight() - h;
          pdf.setPage(_i2);
          pdf.setFontSize(8);
          pdf.setTextColor(150);
          pdf.text(footer, pdf.internal.pageSize.getWidth() - w - 40, pdf.internal.pageSize.getHeight() - h / 2);
          pdf.setFillColor(255, 24, 0, 0);
          pdf.rect(x, y, w, h, "F");
          var img = new Image();
          img.src = '/logo/teelt-logo.png';
          pdf.addImage(img, 'png', x, y, w, h);
        }
      }).save(); // show div with have class "no-print"

      setTimeout(function () {
        for (var _i3 = 0; _i3 < noPrint.length; _i3++) {
          noPrint[_i3].style.display = "block";
        }

        s6ToS12Elements.forEach(function (col) {
          col.classList.remove('s12');
          col.classList.add('s6');
        });
        s4ToS6elements.forEach(function (col) {
          col.classList.remove('s6');
          col.classList.add('s4');
        });
        s4ToS12elements.forEach(function (col) {
          col.classList.remove('s12');
          col.classList.add('s4');
        });
        document.getElementById('report-texts-part').style.display = 'block';
        elem.querySelectorAll('.response-element').forEach(function (responseContainer) {
          responseContainer.style = '';
        });
      }, 100);
    },
    actions: {
      onFilterChanged: function onFilterChanged(data) {
        var complexFilters = this.complexFilters;
        complexFilters.push(data);
        Ember.set(this, 'complexFilters', []);
        Ember.set(this, 'complexFilters', complexFilters);
        Ember.set(this, 'results', undefined);
        this.loadForms();
      },
      removeComplex: function removeComplex(index) {
        var _this4 = this;

        var complexFilters = this.complexFilters;
        complexFilters.splice(index, 1);
        Ember.set(this, 'complexFilters', null);
        Ember.set(this, 'complexFilters', complexFilters);
        Ember.set(this, 'results', undefined);
        this.send('saveFilters');
        setTimeout(function () {
          _this4.loadForms();
        }, 400);
      },
      loadData: function loadData(data) {
        this.loadForms(data);
      },
      saveFilters: function saveFilters() {
        var _this5 = this;

        var complexFilters = this.complexFilters;
        if (this.complexFilters.length == 0) complexFilters = null;
        this.store.findRecord('form-report', this.formReportId, {
          include: 'light'
        }).then(function (formReport) {
          Ember.set(formReport, 'complexFilters', JSON.stringify(complexFilters));
          formReport.save();

          _this5.notify.info(_this5.intl.t('analytics.filter_saved'), {
            closeAfter: 3000
          });
        });
      },
      interceptEnter: function interceptEnter(e) {
        if (e.keyCode == 13) {
          this.send('handleFocusOut', e);
          return false;
        }

        return true;
      },
      handleFocusOut: function handleFocusOut(e) {
        var value = e.target.outerText;
        $('#' + e.target.id).text(value);
        this.send('patchFormReport', value);
        e.preventDefault();
        e.target.blur();
      },
      patchFormReport: function patchFormReport(value) {
        var _this6 = this;

        this.store.findRecord('form-report', this.formReport.id, {
          include: 'light'
        }).then(function (formReport) {
          Ember.set(formReport, 'title', value);
          Ember.set(_this6.formReport, 'title', value);
          formReport.save();
        });
      },
      showPdfPrintModal: function showPdfPrintModal() {
        Ember.set(this, 'showPdfPrintModal', true);
      },
      onClickOverlay: function onClickOverlay() {
        Ember.set(this, 'showPdfPrintModal', false);
      },
      print: function print(withTexts, format) {
        if (format == 'csv') {
          Ember.set(this, 'showPdfPrintModal', false);
          this.exportCsv();
          return;
        }

        this.printPdfReport(withTexts);
        Ember.set(this, 'showPdfPrintModal', false);
      }
    }
  });

  _exports.default = _default;
});