define("teelt-frontend/components/form/forms-list-talent-integration", ["exports", "teelt-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['forms-list-talent-integration'],
    ajax: Ember.inject.service(),
    showRemainingForms: true,
    showFormsCompleted: true,
    displayForm: 'route',
    router: Ember.inject.service(),
    eventBus: Ember.inject.service(),
    loading: true,
    init: function init() {
      this._super.apply(this, arguments);

      this.addFormsResponse();
    },
    extactAllForms: function extactAllForms() {
      var forms = [];

      if (this.talent.template && this.talent.template.periods) {
        var periodIndex = 0;
        var _iteratorNormalCompletion = true;
        var _didIteratorError = false;
        var _iteratorError = undefined;

        try {
          for (var _iterator = this.talent.template.periods[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
            var period = _step.value;

            if (period.tasks) {
              var _iteratorNormalCompletion2 = true;
              var _didIteratorError2 = false;
              var _iteratorError2 = undefined;

              try {
                for (var _iterator2 = period.tasks[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
                  var task = _step2.value;

                  if (task.form) {
                    if (this.adminForms && task.form.type === 'ADMINISTRATIVE' || !this.adminForms && task.form.type !== 'ADMINISTRATIVE') {
                      Ember.set(task.form, 'periodId', period.period.id);
                      Ember.set(task.form, 'periodIndex', periodIndex);
                      Ember.set(task.form, 'startOffset', task.startOffset);
                      Ember.set(task.form, 'startOffsetDirection', task.startOffsetDirection);
                      Ember.set(task.form, 'startOffsetUnit', task.startOffsetUnit);
                      forms.push(task.form);
                    }
                  }
                }
              } catch (err) {
                _didIteratorError2 = true;
                _iteratorError2 = err;
              } finally {
                try {
                  if (!_iteratorNormalCompletion2 && _iterator2.return != null) {
                    _iterator2.return();
                  }
                } finally {
                  if (_didIteratorError2) {
                    throw _iteratorError2;
                  }
                }
              }
            }

            periodIndex++;
          }
        } catch (err) {
          _didIteratorError = true;
          _iteratorError = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion && _iterator.return != null) {
              _iterator.return();
            }
          } finally {
            if (_didIteratorError) {
              throw _iteratorError;
            }
          }
        }
      }

      Ember.set(this, 'forms', forms);
    },
    addFormsResponse: function addFormsResponse() {
      var _this = this;

      this.extactAllForms();
      var promises = [];
      this.forms.forEach(function (form) {
        if (!form.task || form.task.assign === "talent") {
          var path = _environment.default.APP.API_HOST + '/forms/' + form.id + '/response/' + _this.talent.id;
          promises.push(_this.ajax.requestPromise(path, 'GET').then(function (res) {
            if (res.completed && !res.isDraft) {
              Ember.set(form, 'completed', true);
              Ember.set(form, 'result', res.result);
              Ember.set(form, 'maxResult', res.maxResult);
            } else if (res.completed && res.isDraft) {
              Ember.set(form, 'completed', false);
              Ember.set(form, 'result', res.result);
              Ember.set(form, 'maxResult', res.maxResult);
              Ember.set(form, 'isDraft', res.isDraft);
            } else {
              Ember.set(form, 'completed', false);
            }

            Ember.set(form, 'mustBeReviewed', res.mustBeReviewed);
          }));
        }
      });
      Promise.all(promises).then(function () {
        if (!_this.adminForms) {
          Ember.set(_this, 'completedForms', _this.forms.filterBy('completed').filter(function (f) {
            return f.type != 'ADMINISTRATIVE';
          }));
          Ember.set(_this, 'toCompleteForms', _this.forms.filterBy('completed', false).filter(function (f) {
            return f.type != 'ADMINISTRATIVE';
          }));
        } else {
          Ember.set(_this, 'completedForms', _this.forms.filterBy('completed').filter(function (f) {
            return f.type == 'ADMINISTRATIVE' && f.mustBeReviewed != true;
          }));
          Ember.set(_this, 'toCompleteForms', _this.forms.filter(function (form) {
            return (form.completed === false || form.mustBeReviewed === true) && form.type === 'ADMINISTRATIVE';
          }));
        }

        _this.forceForm();

        Ember.set(_this, "loading", false);
      });
    },
    forceForm: function forceForm() {
      if (this.forceFormId) {
        var _iteratorNormalCompletion3 = true;
        var _didIteratorError3 = false;
        var _iteratorError3 = undefined;

        try {
          for (var _iterator3 = this.forms[Symbol.iterator](), _step3; !(_iteratorNormalCompletion3 = (_step3 = _iterator3.next()).done); _iteratorNormalCompletion3 = true) {
            var form = _step3.value;

            if (form.id == this.forceFormId) {
              if (form.completed || form.mustBeReviewed) {
                this.send('showResponses', form);
              } else {
                this.send('startForm', form);
              }

              break;
            }
          }
        } catch (err) {
          _didIteratorError3 = true;
          _iteratorError3 = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion3 && _iterator3.return != null) {
              _iterator3.return();
            }
          } finally {
            if (_didIteratorError3) {
              throw _iteratorError3;
            }
          }
        }
      }
    },
    actions: {
      startForm: function startForm(form) {
        Ember.set(this, 'form', form);

        if (form.mustBeReviewed) {
          Ember.set(this, 'showResponseModal', true);
        } else {
          Ember.set(this, 'showFormModal', true);
        }
      },
      showResponses: function showResponses(form) {
        Ember.set(this, 'form', form);
        Ember.set(this, 'showResponseModal', true);
      },
      onResponseSent: function onResponseSent() {
        this.addFormsResponse();
        Ember.set(this, 'forceFormId', undefined);
        if (this.onResponseSent) this.onResponseSent();
        this.eventBus.trigger('FORCE_RELOAD_TALENT');
      }
    }
  });

  _exports.default = _default;
});