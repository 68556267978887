define("teelt-frontend/components/settings/settings-items", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['settings-items'],
    session: Ember.inject.service(),
    router: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);

      Ember.set(this, "companyId", this.session.data.authenticated.user.companyId);
    },
    actions: {
      open: function open(page) {
        this.router.transitionTo('board.setting.' + page);
      }
    }
  });

  _exports.default = _default;
});