define("teelt-frontend/templates/board/experiences", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "OH88xFzN",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"id\",\"experiences\"],[11,\"class\",\"g-main-section\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"section-container\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"container-fluid\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"col\"],[9],[0,\"\\n          \"],[7,\"div\"],[11,\"class\",\"g-section-title foreground-primary-color\"],[9],[0,\"\\n            \"],[7,\"div\"],[11,\"class\",\"vertical-center\"],[9],[0,\"\\n              \"],[7,\"h1\"],[9],[1,[27,\"capitalize\",[[27,\"t\",[\"word.templates\"],null]],null],false],[10],[0,\"\\n              \"],[1,[27,\"utils/btn-info-tooltip\",null,[[\"info\",\"position\"],[[27,\"t\",[\"page.template.title.tooltip\"],null],\"right\"]]],false],[0,\"\\n            \"],[10],[0,\"\\n            \"],[7,\"div\"],[11,\"class\",\"baseline\"],[9],[1,[27,\"t\",[\"template.baseline\"],null],false],[10],[0,\"\\n          \"],[10],[0,\"\\n        \"],[10],[0,\"\\n\"],[4,\"if\",[[27,\"is-authorize\",[\"ROLE_RH\",\"ROLE_MRH\"],null]],null,{\"statements\":[[0,\"          \"],[7,\"div\"],[11,\"class\",\"col right add-experience-block\"],[9],[0,\"\\n\"],[4,\"link-to\",[\"board.experience.experience-new\",[27,\"query-params\",null,[[\"defaultTemplateId\",\"defaultTemplateType\"],[null,null]]]],[[\"class\"],[\"new-experience-button waves-effect waves-light btn-large background-primary-color\"]],{\"statements\":[[0,\"                \"],[7,\"i\"],[11,\"class\",\"material-icons left\"],[11,\"style\",\"float:left!important\"],[9],[0,\"add\"],[10],[0,\"\\n                \"],[1,[27,\"t\",[\"template.new_template\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"          \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"      \"],[10],[0,\"\\n\\n      \"],[1,[21,\"template/templates-list\"],false],[0,\"\\n\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "teelt-frontend/templates/board/experiences.hbs"
    }
  });

  _exports.default = _default;
});