define("teelt-frontend/models/company", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    domain: _emberData.default.attr('string'),
    emailSender: _emberData.default.attr('string'),
    logoUrl: _emberData.default.attr('string'),
    logoOnBackgroundColorUrl: _emberData.default.attr('string'),
    backgroundUrl: _emberData.default.attr('string'),
    backgroundVideoUrl: _emberData.default.attr('string'),
    faviconUrl: _emberData.default.attr('string'),
    backgroundColor: _emberData.default.attr('string'),
    foregroundColor: _emberData.default.attr('string'),
    createdAt: _emberData.default.attr('date'),
    rhEmails: _emberData.default.attr(),
    selectedToolIds: _emberData.default.attr(),
    customFields: _emberData.default.attr(),
    tools: _emberData.default.attr(),
    users: _emberData.default.attr(),
    useGoogleCalendar: _emberData.default.attr('boolean'),
    useAzureCalendar: _emberData.default.attr('boolean'),
    offices: _emberData.default.attr(),
    integrationMenuNameKey: _emberData.default.attr('string'),
    profileMenuNameKey: _emberData.default.attr('string'),
    resourcesMenuNameKey: _emberData.default.attr('string'),
    teamMenuNameKey: _emberData.default.attr('string'),
    displayTasksEventsOnIntegration: _emberData.default.attr('boolean'),
    canSendSMS: _emberData.default.attr('boolean'),
    smsSender: _emberData.default.attr('string'),
    canCreateHome: _emberData.default.attr('boolean'),
    canImportCSV: _emberData.default.attr('boolean'),
    documentCleanupDelay: _emberData.default.attr('number'),
    presentationText: _emberData.default.attr('string'),
    presentationImageUrl: _emberData.default.attr('string'),
    presentationTitle: _emberData.default.attr('string'),
    displayTeamSection: _emberData.default.attr('boolean'),
    periodBeforeNameKey: _emberData.default.attr('string'),
    periodFirstNameKey: _emberData.default.attr('string'),
    periodAfterNameKey: _emberData.default.attr('string'),
    managerOverrideKey: _emberData.default.attr('string'),
    buddyOverrideKey: _emberData.default.attr('string'),
    showRGPD: _emberData.default.attr('boolean'),
    tagMode: _emberData.default.attr('boolean'),
    autoDisconnectAfterDelay: _emberData.default.attr(),
    deleteUserAfterEndDate: _emberData.default.attr('boolean'),
    deleteAdminFormResponsesAfterXDays: _emberData.default.attr(),
    activeOMRole: _emberData.default.attr('boolean'),
    activeBuddyRole: _emberData.default.attr('boolean'),
    activeAdministrative: _emberData.default.attr('boolean'),
    activeMRH: _emberData.default.attr('boolean'),
    nbRH: _emberData.default.attr('number'),
    nbMRH: _emberData.default.attr('number'),
    nbManager: _emberData.default.attr('number'),
    nbConsult: _emberData.default.attr('number'),
    activeLucca: _emberData.default.attr('boolean'),
    activeSignContent: _emberData.default.attr('boolean'),
    universignApiKey: _emberData.default.attr('string'),
    activeSlack: _emberData.default.attr('boolean'),
    activeCommunication: _emberData.default.attr('boolean'),
    htmlSourceCodeAllowed: _emberData.default.attr('boolean'),
    activeSftp: _emberData.default.attr('boolean'),
    activeDocumentValidation: _emberData.default.attr('boolean'),
    activeTalentSoft: _emberData.default.attr('boolean'),
    talentSoftSetting: _emberData.default.attr(),
    deleteUserAfterXDays: _emberData.default.attr('number'),
    activeAgrume: _emberData.default.attr('boolean'),
    agrumeSetting: _emberData.default.attr(),
    activeFlatchr: _emberData.default.attr('boolean'),
    emailSignature: _emberData.default.attr('string'),
    activePlanningTaskNotif: _emberData.default.attr('boolean'),
    activeAI: _emberData.default.attr('boolean'),
    maxContactAIToken: _emberData.default.attr('number'),
    activeMeetPeople: _emberData.default.attr('boolean'),
    activeExploreOffice: _emberData.default.attr('boolean'),
    essential: _emberData.default.attr('boolean')
  });

  _exports.default = _default;
});