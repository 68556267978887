define("teelt-frontend/services/user-service", ["exports", "teelt-frontend/config/environment", "moment"], function (_exports, _environment, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    intl: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);

      Ember.set(this, 'talentFilters', {});
    },
    forgetPassword: function forgetPassword(email) {
      var url = _environment.default.APP.API_HOST + "/users/password/forget";
      return this.ajax.requestWithBodyPromise(url, 'POST', "application/json", JSON.stringify({
        email: email
      }));
    },
    updatePasswordFromToken: function updatePasswordFromToken(token, newPassword) {
      var url = _environment.default.APP.API_HOST + "/users/password/update";
      return this.ajax.requestWithBodyPromise(url, 'POST', "application/json", JSON.stringify({
        token: token,
        newPassword: newPassword
      }));
    },
    isValidEmail: function isValidEmail(email) {
      var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    },
    sendReinvitation: function sendReinvitation(email) {
      var url = _environment.default.APP.API_HOST + "/users/reinvit";
      return this.ajax.requestWithBodyPromise(url, 'POST', "application/json", JSON.stringify({
        email: email
      }));
    },
    hasAnyRole: function hasAnyRole(user) {
      for (var _len = arguments.length, roles = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
        roles[_key - 1] = arguments[_key];
      }

      for (var i = 0; i < roles.length; i++) {
        for (var j = 0; j < user.roles.length; j++) {
          if (roles[i] === user.roles[j]) return true;
        }
      }

      return false;
    },
    hasAnyRoleArray: function hasAnyRoleArray(user, roles) {
      for (var i = 0; i < roles.length; i++) {
        for (var j = 0; j < user.roles.length; j++) {
          if (roles[i] === user.roles[j]) return true;
        }
      }

      return false;
    },
    isRH: function isRH(user) {
      return this.hasAnyRole(user, 'ROLE_RH');
    },
    isManager: function isManager(user) {
      return this.hasAnyRole(user, 'ROLE_MANAGER');
    },
    isOfficeManager: function isOfficeManager(user) {
      return this.hasAnyRole(user, 'ROLE_CUSTOM_OFFICE_MANAGER');
    },
    isAdministrative: function isAdministrative(user) {
      return this.hasAnyRole(user, 'ROLE_ADMINISTRATIVE');
    },
    isAdminMRH: function isAdminMRH(user) {
      return this.hasAnyRole(user, 'ROLE_MRH');
    },
    isOnlyManager: function isOnlyManager(user, formType) {
      for (var j = 0; j < user.roles.length; j++) {
        if (user.roles[j].roleId != 'ROLE_USER' && user.roles[j].roleId != 'ROLE_MANAGER') {
          return false;
        } else if (user.roles[j].roleId != 'ROLE_USER' && (formType === 'ASTONISHMENT' || formType === 'QUIZZ') && user.roles[j].roleId === 'ROLE_MANAGER') {
          return false;
        }
      }

      return true;
    },
    isOnlyOfficeManager: function isOnlyOfficeManager(user) {
      for (var i = 0; i < user.roles.length; i++) {
        if (user.roles[i] != 'ROLE_USER' && user.roles[i] != 'ROLE_CUSTOM_OFFICE_MANAGER') {
          return false;
        }
      }

      return true;
    },
    isOnlyUser: function isOnlyUser(user) {
      for (var i = 0; i < user.roles.length; i++) {
        if (user.roles[i] !== "ROLE_USER") {
          return false;
        }
      }

      return true;
    },
    getConsultUserRole: function getConsultUserRole(user) {
      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = user.roles[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var role = _step.value;

          if (role.startsWith('ROLE_CONSULT')) {
            return role;
          }
        }
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return != null) {
            _iterator.return();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }
    },
    getTalentName: function getTalentName(talent) {
      var defaultUsername = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;

      if (!talent) {
        return;
      }

      var firstName = talent.firstname;
      var lastName = talent.lastname;

      if (firstName && lastName) {
        return "".concat(firstName, " ").concat(lastName);
      }

      if (defaultUsername) {
        return talent.username;
      }
    },
    getTalentFirstname: function getTalentFirstname(talent) {
      if (!talent) {
        return;
      }

      var firstName = talent.firstname;

      if (firstName) {
        return "".concat(firstName);
      }

      return talent.username;
    },
    getImpersonateRestoreToken: function getImpersonateRestoreToken(talentId) {
      var url = _environment.default.APP.API_HOST + "/users/impersonate/restoretoken/" + talentId;
      return this.ajax.requestPromise(url, 'GET');
    },
    getUserCustomFieldByCodeKey: function getUserCustomFieldByCodeKey(user, codeKey) {
      if (!user || !user.customFields) return;
      var _iteratorNormalCompletion2 = true;
      var _didIteratorError2 = false;
      var _iteratorError2 = undefined;

      try {
        for (var _iterator2 = user.customFields[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
          var field = _step2.value;
          if (field.codeKey === codeKey) return field;
        }
      } catch (err) {
        _didIteratorError2 = true;
        _iteratorError2 = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion2 && _iterator2.return != null) {
            _iterator2.return();
          }
        } finally {
          if (_didIteratorError2) {
            throw _iteratorError2;
          }
        }
      }
    },
    getTalentCurrentPeriod: function getTalentCurrentPeriod(talent, periods) {
      if (!periods || !periods.length) return;
      var triggerDate = periods[0].triggerDate;

      if (triggerDate == 'assignationDate') {
        triggerDate = 'templateAssignationDate';
      }

      if (talent[triggerDate]) {
        for (var i = 0; i < periods.length; i++) {
          var period = periods[i];
          var startDate = (0, _moment.default)(talent[triggerDate]).add(period.offset, 'day');
          var endDate = (0, _moment.default)(talent[triggerDate]).add(period.offset, 'day').add(period.duration, 'day');

          if ((0, _moment.default)().isBetween(startDate, endDate) || i == periods.length - 1 && (0, _moment.default)().isAfter(endDate)) {
            return i;
          }
        }

        return 0;
      }
    },
    getTalentPeriods: function getTalentPeriods(talent) {
      var periods = [];

      if (talent.template && talent.template.periods) {
        periods = talent.template.periods.map(function (p) {
          return p.period;
        });
        periods.sort(function (p1, p2) {
          return p1.offset < p2.offset ? -1 : 1;
        });
      }

      for (var i = 0; i < periods.length; i++) {
        var period = periods[i];
        Ember.set(period, 'index', i);
      }

      return periods;
    },
    getUsersForSelect: function getUsersForSelect(role, initValues) {
      var _this = this;

      var path = this.getUsersSearchPathForSelect(role);
      return this.ajax.requestWithUrlParamPromise(path, 'GET').then(function (managers) {
        var selectValues = managers.map(function (manager) {
          var managerName = _this.getTalentName(manager);

          return {
            key: manager.id,
            label: managerName,
            selected: initValues ? initValues.includes(manager.id) : false
          };
        });
        return selectValues;
      });
    },
    getUsersSearchPathForSelect: function getUsersSearchPathForSelect(role) {
      var path = _environment.default.APP.API_HOST + "/users/roles/" + role;
      return path;
    },
    getOverridableTranslation: function getOverridableTranslation(key, override, defaultValue) {
      if (override && this.intl.exists(override)) {
        return this.intl.t(override);
      }

      if (override && this.intl.exists(key + "." + override)) {
        return this.intl.t(key + "." + override);
      }

      if (this.intl.exists(key + ".default")) {
        return this.intl.t(key + ".default");
      }

      return defaultValue;
    },
    generateApiToken: function generateApiToken() {
      var url = _environment.default.APP.API_HOST + "/users/apitokens";
      return this.ajax.requestPromise(url, 'PATCH');
    },
    getCurrentRole: function getCurrentRole() {
      var currentRole = "ROLE_USER";

      if (this.isRH(this.session.data.authenticated.user)) {
        currentRole = "ROLE_RH";
      } else {
        var _iteratorNormalCompletion3 = true;
        var _didIteratorError3 = false;
        var _iteratorError3 = undefined;

        try {
          for (var _iterator3 = this.session.data.authenticated.user.roles[Symbol.iterator](), _step3; !(_iteratorNormalCompletion3 = (_step3 = _iterator3.next()).done); _iteratorNormalCompletion3 = true) {
            var role = _step3.value;

            if (role != "ROLE_USER") {
              currentRole = role;
              break;
            }
          }
        } catch (err) {
          _didIteratorError3 = true;
          _iteratorError3 = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion3 && _iterator3.return != null) {
              _iterator3.return();
            }
          } finally {
            if (_didIteratorError3) {
              throw _iteratorError3;
            }
          }
        }
      }

      return currentRole;
    },
    getHighestRole: function getHighestRole() {
      var rolePriority = ["ROLE_ADMINISTRATIVE", "ROLE_MANAGER", "ROLE_CUSTOM_OFFICE_MANAGER", "ROLE_RH", "ROLE_ADMIN"];
      var maxIndex = 0;
      var roles = this.session.data.authenticated.user.roles;

      for (var _i = 0, _rolePriority = rolePriority; _i < _rolePriority.length; _i++) {
        var role = _rolePriority[_i];

        if (roles.includes(role)) {
          for (var i = 0; i < roles.length; i++) {
            var roleIndex = rolePriority.indexOf(roles[i]);

            if (roleIndex > maxIndex) {
              maxIndex = roleIndex;
            }
          }

          return rolePriority[maxIndex];
        }
      }

      var _iteratorNormalCompletion4 = true;
      var _didIteratorError4 = false;
      var _iteratorError4 = undefined;

      try {
        for (var _iterator4 = roles[Symbol.iterator](), _step4; !(_iteratorNormalCompletion4 = (_step4 = _iterator4.next()).done); _iteratorNormalCompletion4 = true) {
          var _role = _step4.value;

          if (_role.startsWith('ROLE_CONSULT')) {
            return _role;
          }
        }
      } catch (err) {
        _didIteratorError4 = true;
        _iteratorError4 = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion4 && _iterator4.return != null) {
            _iterator4.return();
          }
        } finally {
          if (_didIteratorError4) {
            throw _iteratorError4;
          }
        }
      }

      return 'ROLE_USER';
    }
  });

  _exports.default = _default;
});